import {useCallback, useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import {setComma, replaceComma} from 'utils/number';

type TProps = {
  onChange?: (money?: number) => void;
  placeholder?: string;
};

const AdminMoneyInput = ({onChange, placeholder = '금액을 입력하세요.'}: TProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [showGuideText, setGuideText] = useState(false);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;

      setGuideText(false);

      if (!!value && isNaN(Number(value))) {
        setGuideText(true);
        return;
      }

      setInputValue(value);
      onChange?.(value === '' ? undefined : Number(replaceComma(value)));
    },
    [onChange]
  );

  const handleFocus = useCallback(() => {
    setInputValue((prev) => (prev ? replaceComma(prev) : prev));
  }, []);

  const handleBlur = useCallback(() => {
    setInputValue((prev) => (prev ? setComma(Number(prev)) : prev));
    setGuideText(false);
  }, []);

  useEffect(() => {
    return () => {
      setInputValue('');
      onChange?.();
      setGuideText(false);
    };
  }, []);

  return (
    <FormControl variant="standard">
      <Input
        id="money"
        value={inputValue}
        onChange={handleChange}
        endAdornment={<InputAdornment position="end">원</InputAdornment>}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {showGuideText && <FormHelperText id="guide">숫자만 입력할 수 있습니다.</FormHelperText>}
    </FormControl>
  );
};

export default AdminMoneyInput;
