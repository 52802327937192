import {ReactNode} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {ReactComponent as NegativeIcon} from 'resources/images/img-negative.svg';

type TProps = {
  title: string;
  description?: string;
  onClick?: VoidFunction;
  buttonText: string;
  buttonStartIcon?: ReactNode;
  buttonEndIcon?: ReactNode;
  style?: object;
};

const CommonError = ({
  title,
  description,
  buttonText,
  buttonStartIcon,
  buttonEndIcon,
  onClick,
  style,
}: TProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        ...style,
      }}
    >
      <NegativeIcon />
      <Box sx={{my: 2}}>
        <Typography variant="h5">{title}</Typography>
        {description && <Typography variant="subtitle1">{description}</Typography>}
      </Box>
      <Button
        onClick={onClick}
        variant="outlined"
        color="error"
        startIcon={buttonStartIcon}
        endIcon={buttonEndIcon}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default CommonError;
