import {Box, TextareaAutosize, Button} from '@mui/material';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {useCallback, useRef} from 'react';
import {TOutstandingRequest} from 'types/api';

type TProps = {
  onSubmit?: (params: Array<TOutstandingRequest>) => void;
};

const OutstandingAmountForm = ({onSubmit}: TProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleClickSubmit = useCallback(() => {
    if (!ref.current) return;

    const value = ref.current.value;

    const result = value
      .split('\n')
      .map((row) => {
        const [userKey, tripOrderId] = row.split(',').map((it) => it.trim());
        return {
          userKey,
          tripOrderId,
        };
      })
      .filter((it) => it.userKey && it.tripOrderId);

    if (result.length) {
      onSubmit?.(result);
    }
  }, [onSubmit]);

  return (
    <VerticalKeyTable
      tableData={[
        [
          {
            key: '미수금 처리',
            value: (
              <Box>
                <TextareaAutosize
                  ref={ref}
                  placeholder={`userKey, tripOrderId\nuserKey, tripOrderId\nuserKey, tripOrderId\nuserKey, tripOrderId`}
                  style={{width: '100%', height: '180px'}}
                />
                <Button variant="contained" onClick={handleClickSubmit}>
                  전송
                </Button>
              </Box>
            ),
          },
        ],
      ]}
    />
  );
};

export default OutstandingAmountForm;
