import {useCallback, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';

type TProps = {
  onChange?: (str: string) => void;
  placeholder: string;
};

const AdminReasonInput = ({placeholder, onChange}: TProps) => {
  const [inputValue, setInputValue] = useState('');
  const [showError, setError] = useState(false);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;

      setError(false);

      if (value.length > 100) {
        setError(true);
        return;
      }

      setInputValue(value);
      onChange?.(value);
    },
    [onChange]
  );

  useEffect(() => {
    return () => {
      setError(false);
      setInputValue('');
    };
  }, []);

  return (
    <TextField
      value={inputValue}
      sx={{width: '100%'}}
      id="reason"
      placeholder={placeholder}
      variant="outlined"
      helperText={showError ? '100자를 넘게 입력할 수 없습니다.' : ''}
      error={showError}
      multiline
      hiddenLabel
      onChange={handleChange}
      data-cy="reason-input"
    />
  );
};

export default AdminReasonInput;
