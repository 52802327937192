import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import useOutstandingAmount from 'hooks/outstandingAmount/useOutstandingAmount';
import {useCallback, useState} from 'react';
import {TOutstandingRequest} from 'types/api';

type TProps = {
  list: TOutstandingRequest[];
  onClose?: () => void;
};

type TStatusOutstandingRequest = {
  status: 'pending' | 'success' | 'error';
} & TOutstandingRequest;

const STATUS_TEXT = {
  pending: '대기',
  success: '결제시도성공',
  error: '결제시도에러',
};

const PurchaseTableModal = ({list, onClose}: TProps) => {
  const [stateList, setStateList] = useState<Array<TStatusOutstandingRequest>>(
    list.map((it) => ({
      ...it,
      status: 'pending',
    }))
  );
  const [isRequested, setIsRequested] = useState(false);
  const [completed, setCompleted] = useState(false);

  const {requestOutstandingPurchase} = useOutstandingAmount();

  const handleClickStartButton = useCallback(async () => {
    if (!list) return;
    setIsRequested(true);
    for (let i = 0; i < list.length; i++) {
      const it = list[i];
      try {
        await requestOutstandingPurchase(it);
        setStateList((prev) => {
          const copied = [...prev];
          copied[i].status = 'success';
          return copied;
        });
      } catch {
        setStateList((prev) => {
          const copied = [...prev];
          copied[i].status = 'error';
          return copied;
        });
      }
    }
    setCompleted(true);
  }, [list, requestOutstandingPurchase]);

  const handleClickClose = useCallback(() => {
    if (!isRequested || completed) {
      onClose?.();
    }
  }, [completed, isRequested, onClose]);

  const STATUS_COLOR = {
    pending: 'black',
    success: '#357a38',
    error: '#ab003c',
  };

  return (
    <Dialog open={true} onClose={handleClickClose} fullWidth>
      <DialogTitle>미수금 설정 내역 확인</DialogTitle>
      <Table style={{background: 'white'}}>
        <TableHead>
          <TableRow>
            <TableCell>유저키</TableCell>
            <TableCell>TripOrderId</TableCell>
            <TableCell>성공여부</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stateList?.map((it) => (
            <TableRow>
              <TableCell>{it.userKey}</TableCell>
              <TableCell>{it.tripOrderId}</TableCell>
              <TableCell
                sx={{
                  color: STATUS_COLOR[it.status],
                }}
              >
                {STATUS_TEXT[it.status]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DialogActions>
        {!isRequested && <Button onClick={handleClickStartButton}>시작</Button>}
        {(completed || !isRequested) && <Button onClick={handleClickClose}>닫기</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseTableModal;
