import TextField from '@mui/material/TextField';

type TProps = {
  title?: string;
  onChange?: (text: string) => void;
  placeholder?: string;
  width?: number;
  testId?: string;
};

const PopupInput = ({title, placeholder, width = 480, testId, onChange}: TProps) => {
  return (
    <TextField
      hiddenLabel
      id="search-text"
      placeholder={placeholder || '제목을 입력해주세요.'}
      variant="standard"
      size="small"
      margin="none"
      value={title}
      sx={{mx: 1, width}}
      onChange={(e) => onChange?.(e.target.value)}
      data-cy={testId}
    />
  );
};

export default PopupInput;
