import {useState, useCallback} from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import AdminDialog from 'components/AdminDialog';
import AdminMoneyInput from 'components/AdminMoneyInput';
import RequiredStar from 'components/RequiredStar';
import {useToastContext} from 'context/ToastContext';
import {PENALTY_LIST, PENALTY_TEXT} from 'constants/App';
import {EPenaltyType} from 'types/api';
import api from 'utils/api';
import {formatMoney} from 'utils/number';
import {delayPageReload} from 'utils/app';

type TProps = {
  open: boolean;
  onClose: VoidFunction;
  tripId?: string;
};

const PenaltyDialog = ({tripId, open, onClose}: TProps) => {
  const {show: showToast} = useToastContext();

  const [openCheckPenalty, setOpenCheckPenalty] = useState(false);
  const [penaltyType, setPenaltyType] = useState<EPenaltyType>(EPenaltyType.TOWAGE);
  const [penaltyMoney, setPenaltyMoney] = useState<number>();

  const handleClosePenalty = useCallback(() => {
    onClose();
    setPenaltyType(EPenaltyType.TOWAGE);
  }, [onClose]);

  const handleConfirmPenalty = useCallback(() => {
    setOpenCheckPenalty(true);
  }, []);

  const handleConfirmCheck = useCallback(() => {
    if (!!tripId && typeof penaltyMoney !== 'undefined') {
      api
        .postPenalty({tripId, paymentAmount: penaltyMoney, penaltyType})
        .then(() => {
          showToast('패널티 처리를 완료했습니다.');
          delayPageReload();
        })
        .finally(() => {
          setOpenCheckPenalty(false);
        });
    }
  }, [tripId, penaltyMoney, penaltyType, showToast]);

  return (
    <>
      <AdminDialog
        open={open}
        title={'패널티 결제 요청'}
        desc={
          <Box sx={{width: 450}}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
              패널티 구분
              <RequiredStar />
            </Typography>
            <FormControl>
              <RadioGroup
                row
                value={penaltyType}
                onChange={(v) => setPenaltyType(v.currentTarget.value as EPenaltyType)}
              >
                {PENALTY_LIST.map((s) => (
                  <FormControlLabel key={s.key} value={s.key} control={<Radio />} label={s.title} />
                ))}
              </RadioGroup>
            </FormControl>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
              패널티 금액
              <RequiredStar />
            </Typography>
            <AdminMoneyInput onChange={setPenaltyMoney} />
          </Box>
        }
        onClose={handleClosePenalty}
        confirmText="결제 요청"
        disableConfirm={!penaltyMoney}
        onConfirm={handleConfirmPenalty}
      />

      <AdminDialog
        title=""
        open={openCheckPenalty}
        desc={
          <Box>
            <Typography variant="h6" color="error">
              <b>{PENALTY_TEXT[penaltyType]}</b> 사유로 <b>{formatMoney(penaltyMoney)}</b> 패널티를
              부과합니다.
            </Typography>
            <Typography variant="subtitle2">사유와 부과요금이 맞는지 확인해주세요.</Typography>
          </Box>
        }
        onClose={() => setOpenCheckPenalty(false)}
        onConfirm={handleConfirmCheck}
      />
    </>
  );
};

export default PenaltyDialog;
