import AppRouter from 'components/AppRouter';
import {ToastProvider} from 'context/ToastContext';

const App = () => {
  return (
    <ToastProvider>
      <AppRouter />
    </ToastProvider>
  );
};

export default App;
