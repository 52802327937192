import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {fetchAuthSession} from 'aws-amplify/auth';
import 'styles/index.scss';
import App from './App';
import {localStorageStore} from 'store/stroageStore';
import {EStorageKey} from 'constants/Storage';

const container = document.getElementById('root');
const root = createRoot(container!);

async function preRender() {
  const result = await fetchAuthSession();
  localStorageStore.updateState({
    [EStorageKey.LOGIN_TOKEN]: result.tokens?.idToken?.toString(),
  });
}

preRender().finally(() => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
});
