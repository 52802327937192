import {useCallback, useEffect, useState} from 'react';
import {generatePath} from 'react-router';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import api from 'utils/api';
import {getSafeDateFormat} from 'utils/date';
import {setComma} from 'utils/number';
import useApi from 'hooks/useApi';
import {TUserTripDetailResponse} from 'types/api';

import PagingTable from 'components/PagingTable';
import {TTableHeader} from 'components/HorizontalKeyTable';
import NetworkError from 'components/NetworkError';
import Duration from 'components/Duration';

import {COMPANY_TEXT, PAYMENT_STATUS_TEXT} from 'constants/App';
import Paths from 'constants/Paths';

type TProps = {
  userId?: string;
};

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호', width: 30},
  {title: '결제일시'},
  {title: '제휴사명', width: 80},
  {title: '기기시리얼넘버', width: 80},
  {title: '이용시작시각'},
  {title: '이용종료시각'},
  {title: '주행시간(s)', align: 'right', width: 80},
  {title: '주행거리(m)', align: 'right', width: 80},
  {title: '최종결제요금', align: 'right', width: 80},
  {title: '결제 상태', width: 80},
];

const PAGING_COUNT = 10;

const UsageDetail = ({userId}: TProps) => {
  const {fetchApi, response, error, loading} = useApi<TUserTripDetailResponse>();
  const [page, setPage] = useState(1);

  const handleClickId = useCallback((id) => {
    const url = generatePath(Paths.PaymentDetail, {id});

    window.open(url, '', 'width=1500,height=800');
  }, []);

  useEffect(() => {
    if (userId) {
      fetchApi(() => api.getUserTripDetail({userId, page, size: PAGING_COUNT}));
    }
  }, [fetchApi, userId, page]);

  if (loading === null || (loading && !response)) {
    return <CircularProgress />;
  }

  if (error) {
    return <NetworkError style={{height: 'calc(100vh - 180px)'}} />;
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{flexGrow: 1, my: 1}}>
        최근 30일 이용 기록
      </Typography>
      <Box>
        <PagingTable
          loading={!!loading}
          headerData={TABLE_HEADER_LIST}
          tableData={
            response?.data?.content.map((c) => [
              c.rowNum,
              getSafeDateFormat(c.updDateTime),
              COMPANY_TEXT[c.company],
              c.vehicleId,
              getSafeDateFormat(c.tripStartDateTime),
              getSafeDateFormat(c.tripEndDateTime),
              <Duration duration={c.duration} />,
              setComma(c.distance),
              setComma(c.paymentAmount),
              <Button onClick={() => handleClickId(c.tripId)}>
                {c.statusDesc || PAYMENT_STATUS_TEXT[c.status]}
              </Button>,
            ]) || []
          }
          pagingCount={PAGING_COUNT}
          totalCount={response?.data?.totalCount || 0}
          onChangePage={setPage}
        />
      </Box>
    </>
  );
};

export default UsageDetail;
