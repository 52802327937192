import {EUserRole} from 'constants/App';
import Store from './base';
import {ECompanyType} from 'types/api';
import {EStorageKey} from 'constants/Storage';
import {decodeJwt} from 'jose';
import {localStorageStore} from './stroageStore';

export type TLoginData = {
  name?: string;
  role?: EUserRole;
  accountId?: number;
  companyCode?: ECompanyType;
};

class LoginDataStore extends Store<TLoginData> {
  constructor() {
    super({
      name: undefined,
      role: undefined,
      accountId: undefined,
      companyCode: undefined,
    });
    const token = localStorage.getItem(EStorageKey.LOGIN_TOKEN);
    const data = token ? (decodeJwt(token) as TLoginData) : undefined;

    if (data?.name) {
      this.setState(data);
    }
  }

  logout = () => {
    localStorageStore.remove(EStorageKey.LOGIN_TOKEN);
    window.location.reload();
  };

  getState = () => {
    return this.state;
  };
}

const loginDataStore = new LoginDataStore();

export default loginDataStore;
