import {useCallback, useState} from 'react';
import {Box} from '@mui/material';
import {TDateRange} from 'components/AdminDateRangePicker';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {useToastContext} from 'context/ToastContext';
import {EInUseStatus, ELandingUrlType, IN_USE_STATUS_LIST} from 'constants/App';
import {EUploadImageType, TEventPageItem, TEventPageUpdateParams} from 'types/api';
import {getSafeDateFormat, parseDate} from 'utils/date';
import PopupButtonGroup from './PopupButtonGroup';
import PopupDetailLink from './PopupDetailLink';
import PopupFormGroup from './PopupFormGroup';
import PopupInput from './PopupInput';
import PopupTimePicker from './PopupTimePicker';
import PopupImageUpload from './PopupImageUpload';
import api from 'utils/api';
import FileUploadContainer from 'components/FileUploadContainer';
import useFileUpload from 'hooks/useFileUpload';

type TProps = Partial<TEventPageItem> & {
  onSave?: (params: TEventPageUpdateParams) => void;
};

const EventPagePopup = ({onSave, ...props}: TProps) => {
  const {show: showAlert} = useToastContext();

  const [title, setTitle] = useState(props.title || '');
  const [buttonName, setButtonName] = useState(props.buttonName || '');
  const [buttonColor, setButtonColor] = useState((props.buttonColor || '').replace(/^#/, ''));
  const {uploadedImage, setUploadedImage, imageUrl} = useFileUpload(props.imgUrl);

  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: parseDate(props.startDateTime),
    endDate: parseDate(props.endDateTime),
  });

  const [landingUrl, setLandingUrl] = useState(props.landingUrl || '');
  const [landingUrlType, setLandingUrlType] = useState<ELandingUrlType>(
    props.landingUrl ? ELandingUrlType.HAS_URL : ELandingUrlType.NONE
  );

  const [inUseStatus, setInUseStatus] = useState<EInUseStatus>(
    props.isUse ? EInUseStatus.IN_USE : EInUseStatus.NOT_IN_USE
  );

  const handleClickSave = useCallback(async () => {
    const img = imageUrl || uploadedImage;

    if (!title || !dateRange.startDate || !dateRange.endDate || !img) {
      showAlert('필수 값을 모두 입력해주세요.');
      return;
    }

    let imgUrl = imageUrl;

    if (uploadedImage) {
      const {data} = await api.uploadImage({
        type: EUploadImageType.EVENT,
        file: uploadedImage,
      });

      imgUrl = data;
    }

    onSave?.({
      title,
      imgUrl,
      landingUrl: landingUrlType === ELandingUrlType.HAS_URL ? landingUrl : undefined,
      isUse: inUseStatus === EInUseStatus.IN_USE ? true : false,
      startDateTime: getSafeDateFormat(dateRange.startDate),
      endDateTime: getSafeDateFormat(dateRange.endDate),
      buttonName,
      buttonColor: `#${buttonColor}`,
    });
  }, [
    title,
    landingUrl,
    landingUrlType,
    dateRange,
    buttonName,
    buttonColor,
    showAlert,
    onSave,
    inUseStatus,
    imageUrl,
    uploadedImage,
  ]);

  return (
    <FileUploadContainer onUpload={setUploadedImage}>
      <Box>
        <VerticalKeyTable
          tableData={[
            [
              {
                key: '제목',
                align: 'left',
                required: true,
                value: <PopupInput testId="title" title={title} onChange={setTitle} />,
              },
            ],
            [
              {
                key: '게시기간',
                align: 'left',
                required: true,
                value: (
                  <PopupTimePicker
                    testId="dateRange"
                    dateRange={dateRange}
                    onDateChange={setDateRange}
                  />
                ),
              },
            ],
            [
              {
                key: '이미지',
                align: 'left',
                required: true,
                value: (
                  <PopupImageUpload
                    prevImgUrl={props.imgUrl}
                    imgUrl={imageUrl}
                    onUploadImage={setUploadedImage}
                  />
                ),
              },
            ],
            [
              {
                key: '상세링크',
                align: 'left',
                value: (
                  <PopupDetailLink
                    landingUrl={landingUrl}
                    onChange={setLandingUrl}
                    landingUrlType={landingUrlType}
                    onChangeType={setLandingUrlType}
                    testId="detailLink"
                  />
                ),
              },
            ],
            [
              {
                key: '버튼명',
                align: 'left',
                value: (
                  <PopupInput
                    title={buttonName}
                    onChange={setButtonName}
                    placeholder="버튼명을 입력해주세요."
                  />
                ),
              },
            ],
            [
              {
                key: '버튼 색상',
                align: 'left',
                value: (
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    #
                    <PopupInput
                      title={buttonColor}
                      onChange={setButtonColor}
                      width={100}
                      placeholder="색상"
                    />
                    {buttonColor && (
                      <Box sx={{background: `#${buttonColor}`, width: 30, height: 30}} />
                    )}
                  </Box>
                ),
              },
            ],

            [
              {
                key: '게시 여부',
                align: 'left',
                value: (
                  <PopupFormGroup
                    list={IN_USE_STATUS_LIST}
                    value={inUseStatus}
                    onChange={setInUseStatus}
                  />
                ),
              },
            ],
          ]}
        />
        <PopupButtonGroup onSave={handleClickSave} />
      </Box>
    </FileUploadContainer>
  );
};

export default EventPagePopup;
