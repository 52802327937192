import {useCallback} from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import {getTitleWithEnv} from 'utils/string';
import {ReactComponent as HelmetIcon} from 'resources/images/img-helmet.svg';
import useLoginDataStore from 'hooks/useLoginDataStore';
import useAuth from 'hooks/useAuth';

const Header = () => {
  const {name} = useLoginDataStore();
  const {logout} = useAuth();

  const handleClickLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Box sx={{flexGrow: 1, bgcolor: 'primary.dark', color: 'white'}}>
      <Toolbar variant="dense">
        <Typography variant="subtitle1" sx={{flexGrow: 1, fontWeight: 800}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{mr: 1, width: 32, height: 32}}>
              <HelmetIcon width={32} height={32} />
            </Box>
            <Link to="/">{getTitleWithEnv('PM BACKOFFICE')}</Link>
          </Box>
        </Typography>

        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {name && (
            <>
              <Typography variant="subtitle2" px={1}>
                {name}
              </Typography>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  mx: 1,
                }}
              />
            </>
          )}
          <Button color="inherit" onClick={handleClickLogout}>
            <Typography variant="subtitle2">로그아웃</Typography>
          </Button>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Header;
