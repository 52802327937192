import CommonError from './CommonError';
import ReplayIcon from '@mui/icons-material/Replay';

type TProps = {
  style?: object;
};

const NetworkError = ({style}: TProps) => {
  return (
    <CommonError
      title="네트워크 에러가 발생하였습니다."
      buttonText="새로고침"
      onClick={() => window.location.reload()}
      buttonEndIcon={<ReplayIcon />}
      style={style}
    />
  );
};

export default NetworkError;
