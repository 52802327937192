import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Upload} from '@mui/icons-material';

import s from 'styles/components/FileUploadContainer.module.scss';

type TProps = {
  onUpload: (file: File) => void;
} & PropsWithChildren;

const FileUploadContainer = ({children, onUpload}: TProps) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    const handleDropEvent = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!e.dataTransfer) {
        return;
      }
      setIsDragOver(false);
      const items = e.dataTransfer.items;
      const firstItem = items[0];

      if (firstItem.kind === 'file') {
        const file = firstItem.getAsFile();
        if (file) {
          onUpload(file);
        }
        return;
      }

      const files = e.dataTransfer.files;
      const firstFile = files[0];
      if (firstFile) {
        onUpload(firstFile);
      }
    };

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragOver(false);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer?.files) {
        setIsDragOver(true);
      }
    };

    if (refContainer.current) {
      refContainer.current.addEventListener('drop', handleDropEvent);
      refContainer.current.addEventListener('dragenter', handleDragEnter);
      refContainer.current.addEventListener('dragleave', handleDragLeave);
      refContainer.current.addEventListener('dragover', handleDragOver);
    }
  }, []);

  return (
    <div className={s.wrapper} ref={refContainer}>
      {isDragOver && (
        <div className={s.notice}>
          <Upload />
        </div>
      )}
      {children}
    </div>
  );
};

export default FileUploadContainer;
