import {ReactElement} from 'react';
import PaidIcon from '@mui/icons-material/Paid';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CampaignIcon from '@mui/icons-material/Campaign';

import Paths from './Paths';
import {isProdServer} from './Env';

const iconStyle = {
  sx: {color: 'text.primary', mr: 1},
};

export type TSideMenu = {
  title: string;
  icon: ReactElement;
  subList: {title: string; to: string}[];
};

export const SIDE_MENUS: TSideMenu[] = [
  {
    title: '회원관리',
    icon: <ManageAccountsIcon fontSize="small" {...iconStyle} />,
    subList: [
      {
        title: '가입자 지표',
        to: Paths.MemberStatus,
      },
      {
        title: '회원관리',
        to: Paths.MemberManagement,
      },
    ],
  },
  {
    title: '서비스 운영',
    icon: <ListOutlinedIcon fontSize="small" {...iconStyle} />,
    subList: [
      {
        title: '이용 내역 조회',
        to: Paths.UsageHistory,
      },
    ],
  },
  {
    title: '공지관리',
    icon: <CampaignIcon fontSize="small" {...iconStyle} />,
    subList: [
      {
        title: '배너 관리',
        to: Paths.NoticeTextBanner,
      },
      {
        title: '홈팝업 관리',
        to: Paths.NoticeHomePopup,
      },
      {
        title: '이벤트 페이지 관리',
        to: Paths.NoticeEventPage,
      },
    ],
  },
  {
    title: '정산',
    icon: <PaidIcon fontSize="small" {...iconStyle} />,
    subList: [
      {
        title: '일반 이용 정산',
        to: Paths.CompanyInvoice,
      },
      {
        title: '미결제 내역',
        to: Paths.CompanyUnsettled,
      },
      {
        title: '미수금 결제',
        to: Paths.OutstandingAmount,
      },
    ],
  },
].concat(
  isProdServer
    ? []
    : [
        {
          title: 'Proto',
          icon: <DoneAllIcon fontSize="small" {...iconStyle} />,
          subList: [
            {
              title: '공통 컴포넌트',
              to: Paths.ProtoComponent,
            },
          ],
        },
      ]
);
