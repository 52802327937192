import {useCallback, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HorizontalKeyTable, {TTableHeader} from './HorizontalKeyTable';

type TProps = {
  headerData: TTableHeader[];
  tableData: any[][];
  pagingCount?: number;
  totalCount: number;
  loading?: boolean;
  onChangePage?: (page: number) => void;
};

const DEFAULT_PAGING_COUNT = 20;
const MAX_PAGE_COUNT = 10;
const PICKER_COLOR = 'grey.700';

const buttonStyle = {
  mt: 1,
  mb: 1,
  borderColor: PICKER_COLOR,
  fontSize: 'inherit',
};

const PagingTable = ({
  loading,
  headerData,
  tableData,
  totalCount,
  pagingCount = DEFAULT_PAGING_COUNT,
  onChangePage,
}: TProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageList = useMemo(
    () => new Array(Math.ceil(totalCount / pagingCount)).fill(null).map((_, i) => i + 1),
    [pagingCount, totalCount]
  );

  const [startIndex, setStartIndex] = useState(0);

  const handleClickPage = useCallback(
    (page) => {
      setCurrentPage(page);
      onChangePage?.(page);
    },
    [onChangePage]
  );

  const goToFirstPage = useCallback(() => {
    const page = 1;

    setStartIndex(0);
    setCurrentPage(page);
    onChangePage?.(page);
  }, [onChangePage]);

  const goToLastPage = useCallback(() => {
    const page = pageList.length;

    setStartIndex(Math.floor(page / MAX_PAGE_COUNT) * MAX_PAGE_COUNT);
    setCurrentPage(page);
    onChangePage?.(page);
  }, [pageList, onChangePage]);

  return (
    <>
      <Box sx={{mb: 10, width: '100%'}}>
        <HorizontalKeyTable headerData={headerData} tableData={tableData} loading={loading} />
      </Box>
      {pageList.length > 0 && (
        <ButtonGroup
          variant="outlined"
          color="inherit"
          size="small"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 0,
            background: 'white',
            width: '100%',
            borderTop: '#efefef 1px solid',
            borderRadius: '0px',
          }}
        >
          {pageList.length > MAX_PAGE_COUNT && (
            <>
              <Button sx={buttonStyle} onClick={goToFirstPage} disabled={startIndex === 0}>
                <DoubleArrowIcon fontSize="small" sx={{transform: 'rotate(180deg)'}} />
              </Button>
              <Button
                sx={buttonStyle}
                onClick={() => setStartIndex((prev) => prev - MAX_PAGE_COUNT)}
                disabled={startIndex === 0}
              >
                <ArrowBack fontSize="small" />
              </Button>
            </>
          )}
          {pageList.slice(startIndex, startIndex + MAX_PAGE_COUNT).map((page) => {
            const isSelected = page === currentPage;

            return (
              <Button
                key={page}
                onClick={() => handleClickPage(page)}
                sx={{
                  ...buttonStyle,
                  backgroundColor: isSelected ? 'primary.dark' : '',
                  color: isSelected ? 'white' : '',
                  borderColor: PICKER_COLOR,
                }}
              >
                {page}
              </Button>
            );
          })}
          {pageList.length > MAX_PAGE_COUNT && (
            <>
              <Button
                sx={buttonStyle}
                onClick={() => setStartIndex((prev) => prev + MAX_PAGE_COUNT)}
                disabled={startIndex + MAX_PAGE_COUNT >= pageList.length}
              >
                <ArrowForward fontSize="small" />
              </Button>
              <Button
                sx={buttonStyle}
                onClick={goToLastPage}
                disabled={startIndex + MAX_PAGE_COUNT >= pageList.length}
              >
                <DoubleArrowIcon fontSize="small" />
              </Button>
            </>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

export default PagingTable;
