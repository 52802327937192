import {useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NoticeHomePopup from 'components/notice/HomePopup';
import {getTitleWithEnv} from 'utils/string';
import api from 'utils/api';
import {TPopupUpdateParams} from 'types/api';

const CreateNoticeHomePopup = () => {
  const handleSave = useCallback((data: TPopupUpdateParams) => {
    api
      .postHomePopupDetail(data)
      .then(() => {
        window.alert('팝업 생성을 완료하였습니다.');
        window.opener?.location.reload();
        window.close();
      })
      .catch(() => {
        window.location.reload();
      });
  }, []);

  return (
    <Box p={3}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('팝업 새로 만들기')}
      </Typography>

      <Box my={3}>
        <NoticeHomePopup onSave={handleSave} />
      </Box>
    </Box>
  );
};

export default CreateNoticeHomePopup;
