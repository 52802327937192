import {useCallback, useEffect, useMemo, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import VerticalKeyTable from 'components/VerticalKeyTable';
import AdminDialog from 'components/AdminDialog';
import AdminReasonInput from 'components/AdminReasonInput';
import NetworkError from 'components/NetworkError';
import RequiredStar from 'components/RequiredStar';
import {EUserStatus, TUserDetailResponse} from 'types/api';
import useApi from 'hooks/useApi';
import {USER_STATUS_LIST, USER_STATUS_TEXT} from 'constants/App';
import api from 'utils/api';
import {formatBirthday, formatPhoneNumber} from 'utils/number';
import {getSafeDateFormat} from 'utils/date';
import {useToastContext} from 'context/ToastContext';

type TProps = {
  userId?: string;
};

const MemberManageDetail = ({userId}: TProps) => {
  const {show: showToast} = useToastContext();
  const [open, setOpen] = useState(false);
  const [userStatus, setUserStatus] = useState(EUserStatus.NORMAL);
  const [inputValue, setInputValue] = useState('');
  const {fetchApi, response, error, loading} = useApi<TUserDetailResponse>();
  const data = useMemo(() => response?.data, [response]);

  const handleConfirm = useCallback(async () => {
    if (!userId) {
      return;
    }

    if (userStatus === EUserStatus.BLOCK && !inputValue) {
      showToast('이용제한 사유를 입력해주세요.');
      return;
    }

    try {
      const result = await api.postUserStatus({
        userId,
        blockStatus: userStatus,
        comment: inputValue,
      });

      showToast(`${USER_STATUS_TEXT[result.data as EUserStatus]} 상태로 변경되었습니다.`, {
        type: 'success',
      });
    } catch {
      showToast('오류가 발생했습니다.');
    } finally {
      setOpen(false);
      setInputValue('');
      fetchApi(() => api.getUserDetail({userId}));
    }
  }, [userId, userStatus, inputValue, showToast, fetchApi]);

  useEffect(() => {
    if (userId) {
      fetchApi(() =>
        api.getUserDetail({userId}).catch(() => {
          window.close();
        })
      );
    }
  }, [fetchApi, userId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!data || error) {
    return <NetworkError style={{height: 'calc(100vh - 180px)'}} />;
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{flexGrow: 1, my: 1}}>
        회원정보
      </Typography>
      <VerticalKeyTable
        tableData={[
          [
            {key: '유저키', value: data.userKey},
            {key: 'UserId', value: data.userId},
            {key: '이름', value: data.userName},
          ],
          [
            {key: '핸드폰 번호', value: formatPhoneNumber(data.phone)},
            {
              key: '회원 상태',
              value: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {USER_STATUS_TEXT[data.blockStatus]}
                  <Button
                    data-cy="change"
                    sx={{padding: 0, fontSize: 13, ml: 1}}
                    onClick={() => setOpen(true)}
                  >
                    변경
                  </Button>
                </Box>
              ),
            },
            {key: '이용제한 사유', value: data.blockComment || ''},
          ],
          [
            {key: 'TID', value: data.tid},
            {
              key: '생년월일',
              value: formatBirthday(data.birth),
            },
            {key: 'OS 종류', value: data.lastAccessOsType},
          ],
          [
            {key: '가입 일시', value: getSafeDateFormat(data.activatedDt)},
            {key: '최근 접속 일시', value: getSafeDateFormat(data.lastAccessDateTime)},
            {key: '면허 등록 일시', value: getSafeDateFormat(data.licenseVerifiedDt)},
          ],
        ]}
      />
      <AdminDialog
        open={open}
        title={'회원 상태 정보 변경'}
        desc={
          <Box sx={{width: 350}}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
              회원 상태
              <RequiredStar />
            </Typography>
            <FormControl>
              <RadioGroup
                row
                value={userStatus}
                onChange={(v) => setUserStatus(v.currentTarget.value as EUserStatus)}
              >
                {USER_STATUS_LIST.map((s) => (
                  <FormControlLabel
                    key={s.key}
                    value={s.key}
                    control={<Radio data-cy={`radio-${s.key}`} />}
                    label={s.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
              변경사유 {userStatus === EUserStatus.BLOCK && <RequiredStar />}
            </Typography>

            <AdminReasonInput
              onChange={(value) => setInputValue(value)}
              placeholder="이용제한 사유를 입력하세요."
            />
          </Box>
        }
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default MemberManageDetail;
