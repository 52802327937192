import {ReactNode} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type TProps = {
  open: boolean;
  title: string;
  desc: ReactNode;
  onClose?: VoidFunction;
  onConfirm?: VoidFunction;
  disableConfirm?: boolean;
  cancelText?: string;
  confirmText?: string;
};

const AdminDialog = ({
  open,
  title,
  desc,
  onClose,
  onConfirm,
  cancelText = '취소',
  confirmText = '확인',
  disableConfirm = false,
}: TProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{width: 'fitContent'}}>
        <DialogContentText>{desc}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {cancelText}
        </Button>
        <Button
          data-cy="confirm"
          onClick={onConfirm}
          variant="contained"
          autoFocus
          disabled={disableConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminDialog;
