import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {SIDE_MENUS} from 'constants/Menu';
import {getTitleWithEnv} from 'utils/string';

const getRouteData = (pathname: string) => {
  const d = SIDE_MENUS.map((v) => ({
    parent: v,
    sub: v.subList.find((s) => s.to === pathname),
  })).find((v) => !!v.sub);

  return {
    breadcrumbs: d ? ['홈', d.parent.title, d.sub?.title || ''] : [],
    title: d?.sub?.title || '',
    icon: d?.parent?.icon,
  };
};

type TProps = {
  onToggleSidebar: VoidFunction;
};

const Title = ({onToggleSidebar}: TProps) => {
  const location = useLocation();
  const routeData = useMemo(() => getRouteData(location.pathname), [location]);

  useEffect(() => {
    if (routeData.title) {
      document.title = getTitleWithEnv(`PM BACKOFFICE : ${routeData.title}`);
    }
  }, [routeData.title]);

  return (
    <Box
      px={3}
      py={1.5}
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <MenuRoundedIcon sx={{mr: 1}} onClick={onToggleSidebar} />

        <Typography variant="subtitle1" sx={{flexGrow: 1}}>
          {routeData.title}
        </Typography>

        <Breadcrumbs>
          {routeData.breadcrumbs.map((t, i) => (
            <Typography
              variant="subtitle2"
              key={i}
              sx={{display: 'flex', alignItems: 'center'}}
              color="text.primary"
            >
              {t}
            </Typography>
          ))}
        </Breadcrumbs>
      </Box>
      {/* <Divider /> */}
    </Box>
  );
};

export default Title;
