import {useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import {ReactComponent as HelmetIcon} from 'resources/images/img-helmet.svg';
import {getTitleWithEnv} from 'utils/string';
import useApi from 'hooks/useApi';
import {TSignInResponse} from 'types/api';
import useAuth from 'hooks/useAuth';

const LoginPage = () => {
  const {login} = useAuth();
  const {loading} = useApi<TSignInResponse>();

  const handleLogin = useCallback(async () => {
    login();
  }, [login]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleLogin();
    },
    [handleLogin]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'primary.light',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: 500,
          height: 500,
          backgroundColor: 'white',
        }}
      >
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <HelmetIcon />
          <Typography my={2} variant="h5">
            {getTitleWithEnv('PM BACKOFFICE')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{display: 'flex', flexDirection: 'column', width: 300}}>
            <LoadingButton
              variant="contained"
              type="submit"
              onClick={handleLogin}
              loading={!!loading}
            >
              SSO 로그인
            </LoadingButton>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LoginPage;
