import {useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

export type TSelectBoxItem = {key?: string; title: string};

type TProps = {
  currentItem: TSelectBoxItem;
  selectList: TSelectBoxItem[];
  testId?: string;
  onClick?: VoidFunction;
  onClickItem?: (p: TSelectBoxItem) => void;
};

const AdminSelectBox = ({currentItem, selectList, testId, onClick, onClickItem}: TProps) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      onClick?.();
    },
    [onClick]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickItem = useCallback(
    (menu: TSelectBoxItem) => {
      handleClose();
      onClickItem?.(menu);
    },
    [handleClose, onClickItem]
  );

  return (
    <Box sx={{color: 'text.secondary'}}>
      <Button
        id="admin-menu-button"
        aria-controls={isOpen ? 'admin-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        color="inherit"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        data-cy={testId}
      >
        {currentItem.title}
      </Button>
      <Menu
        id="admin-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        data-cy="admin-menu"
      >
        {selectList.map((m, i) => (
          <MenuItem data-cy={m.title} key={m.key || i} onClick={() => handleClickItem(m)}>
            {m.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AdminSelectBox;
