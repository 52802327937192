import {useCallback, useState} from 'react';
import {TResponse} from 'types/api';
import {CustomError} from 'utils/normalizeError';

type TApiResponse<T> = TResponse<T>;
type TDefaultApiResponse = Record<string, number | string | boolean>;

type TReturnValue<T> = {
  fetchApi: (api: () => Promise<any>) => Promise<TApiResponse<T>>;
  loading: Nullable<boolean>;
  error?: CustomError;
  response?: TApiResponse<T>;
  resetResponse: () => void;
};

const useApi = <T = TDefaultApiResponse>(): TReturnValue<T> => {
  const [loading, setLoading] = useState<Nullable<boolean>>(null);
  const [error, setError] = useState<CustomError>();
  const [response, setResponse] = useState<TApiResponse<T>>();

  const trigger = useCallback((api) => {
    setLoading(true);
    setError(undefined);

    return api()
      .then((res: TApiResponse<T>) => {
        setLoading(false);
        setResponse(res);

        return res;
      })
      .catch((e: CustomError) => {
        setLoading(false);
        setError(e);

        throw e;
      });
  }, []);

  return {
    fetchApi: trigger,
    loading,
    error,
    response,
    resetResponse: () => setResponse(undefined),
  };
};

export default useApi;
