import {Navigate, Route, Routes} from 'react-router';
import MemberStatusPage from 'pages/MemberStatusPage';
import CompanyInvoicePage from 'pages/CompanyInvoicePage';
import UsageHistoryPage from 'pages/UsageHistoryPage';
import MemberManagementPage from 'pages/MemberManagementPage';
import ProtoComponentPage from 'pages/ProtoComponentPage';
import LoginPage from 'pages/LoginPage';
import MemberManageDetailPage from 'pages/MemberManageDetailPage';
import PaymentDetailPage from 'pages/PaymentDetailPage';
import CompanyUnsettledPage from 'pages/CompanyUnsettledPage';
import NoticeHomePage from 'pages/NoticeHomePage';
import NoticeTextBannerPage from 'pages/NoticeTextBannerPage';
import NoticeEventPage from 'pages/NoticeEventPage';
import CreateNoticeHomePopup from 'pages/CreateNoticeHomePopup';
import UpdateNoticeHomePopup from 'pages/UpdateNoticeHomePopup';
import CreateTextBannerPopup from 'pages/CreateTextBannerPopup';
import UpdateTextBannerPopup from 'pages/UpdateTextBannerPopup';
import CreateEventPagePopup from 'pages/CreateEventPagePopup';
import UpdateEventPagePopup from 'pages/UpdateEventPagePopup';
import OutstandingAmountPage from 'pages/OutstandingAmountPage';

import CommonLayout from 'components/CommonLayout';
import {EUserRole} from 'constants/App';
import NotFound from 'components/NotFound';
import {isProdServer} from 'constants/Env';
import Paths from 'constants/Paths';
import {canAccessPage} from 'utils/router';
import useLoginDataStore from 'hooks/useLoginDataStore';
import useAuth from 'hooks/useAuth';
import {useEffect} from 'react';

const PageMap = {
  [Paths.MemberStatus]: <MemberStatusPage />,
  [Paths.MemberManagement]: <MemberManagementPage />,
  [Paths.UsageHistory]: <UsageHistoryPage />,
  [Paths.CompanyInvoice]: <CompanyInvoicePage />,
  [Paths.CompanyUnsettled]: <CompanyUnsettledPage />,
  [Paths.NoticeHomePopup]: <NoticeHomePage />,
  [Paths.NoticeTextBanner]: <NoticeTextBannerPage />,
  [Paths.NoticeEventPage]: <NoticeEventPage />,
  [Paths.OutstandingAmount]: <OutstandingAmountPage />,
  ...(!isProdServer && {
    [Paths.ProtoComponent]: <ProtoComponentPage />,
  }),
};

const MainPageMap = {
  [EUserRole.ADMINISTRATOR]: Paths.MemberStatus,
  [EUserRole.SUPPORTER]: Paths.UsageHistory,
  [EUserRole.PARTNER]: Paths.UsageHistory,
  [EUserRole.OPERATOR]: Paths.MemberStatus,
};

const renderPageRoutes = (role?: EUserRole) =>
  Object.keys(PageMap)
    .filter((p) => canAccessPage(p, role))
    .map((p) => <Route key={p} path={p} element={<CommonLayout>{PageMap[p]}</CommonLayout>} />);

const AppRouter = () => {
  const {role} = useLoginDataStore();
  const {checkAuth} = useAuth();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={role ? MainPageMap[role] : Paths.Login} />} />
      <Route path={Paths.MemberManageDetail} element={<MemberManageDetailPage />} />
      <Route path={Paths.PaymentDetail} element={<PaymentDetailPage />} />
      <Route path={Paths.CreateNoticeHomePopup} element={<CreateNoticeHomePopup />} />
      <Route path={Paths.UpdateNoticeHomePopup} element={<UpdateNoticeHomePopup />} />
      <Route path={Paths.CreateTextBannerPopup} element={<CreateTextBannerPopup />} />
      <Route path={Paths.UpdateTextBannerPopup} element={<UpdateTextBannerPopup />} />
      <Route path={Paths.CreateEventPagePopup} element={<CreateEventPagePopup />} />
      <Route path={Paths.UpdateEventPagePopup} element={<UpdateEventPagePopup />} />
      <Route path={Paths.Login} element={<LoginPage />} />
      {renderPageRoutes(role)}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
