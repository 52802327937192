import {useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EventPagePopup from 'components/notice/EventPagePopup';
import {getTitleWithEnv} from 'utils/string';
import api from 'utils/api';
import {TEventPageUpdateParams} from 'types/api';

const CreateEventPagePopup = () => {
  const handleSave = useCallback((data: TEventPageUpdateParams) => {
    api
      .postEventPageDetail(data)
      .then(() => {
        window.alert('이벤트 페이지 생성을 완료하였습니다.');
        window.opener?.location.reload();
        window.close();
      })
      .catch(() => {
        window.location.reload();
      });
  }, []);

  return (
    <Box p={3} sx={{overflowY: 'scroll', height: '80vh'}}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('이벤트 페이지 새로 만들기')}
      </Typography>

      <Box my={3}>
        <EventPagePopup
          onSave={handleSave}
          landingUrl="tmap://kickboard"
          buttonName="티맵 킥보드 바로가기"
        />
      </Box>
    </Box>
  );
};

export default CreateEventPagePopup;
