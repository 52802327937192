class Store<T = any> {
  private listeners: Function[] = [];
  private _state: T;

  constructor(defaultState: T) {
    this._state = defaultState;
  }

  get state() {
    return this._state;
  }

  getState = () => {
    return this._state;
  };

  setState = (nextState: T, isUpdate = true) => {
    this._state = nextState;
    if (isUpdate) {
      this.emitChange();
    }
  };

  updateState = (nextState: Partial<T>, isUpdate = true) => {
    if (Array.isArray(this._state)) {
      this._state = nextState as any;
      if (isUpdate) {
        this.emitChange();
      }
      return;
    }

    if (typeof this._state === 'object' && typeof nextState === 'object') {
      this._state = {
        ...this.state,
        ...nextState,
      };
    } else {
      this._state = nextState as any;
    }

    if (isUpdate) {
      this.emitChange();
    }
  };

  subscribe = (listener: Function) => {
    this.listeners.push(listener);

    return () => {
      this.listeners.filter((it) => it !== listener);
    };
  };

  emitChange = () => {
    this.listeners.forEach((it) => it());
  };
}

export default Store;
