import {useCallback, useEffect, useState} from 'react';
import {generatePath} from 'react-router-dom';
import {subDays} from 'date-fns';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import AdminExcelDownButton from 'components/AdminExcelDownButton';
import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';
import AdminSelectBox, {TSelectBoxItem} from 'components/AdminSelectBox';
import AdminCompanySelectBox, {COMPANY_SELECT_LIST} from 'components/AdminCompanySelectBox';
import PagingTable from 'components/PagingTable';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {TTableHeader} from 'components/HorizontalKeyTable';
import Duration from 'components/Duration';

import {EPaymentStatus, ECompanyType, ESearchType, TTripListResponse} from 'types/api';
import {COMPANY_TEXT, PAYMENT_STATUS_TEXT, SEARCH_TYPE_TEXT, today} from 'constants/App';
import useApi from 'hooks/useApi';
import api from 'utils/api';
import {formatMoney, setComma} from 'utils/number';
import {getSafeDateFormat} from 'utils/date';
import {downloadExcel} from 'utils/file';
import Paths from 'constants/Paths';
import {ErrorMessage} from 'constants/Error';
import {useToastContext} from 'context/ToastContext';

const PAYMENT_STATUS_SELECT_LIST: TSelectBoxItem[] = [
  {key: undefined, title: '전체'},
  {
    key: EPaymentStatus.PURCHASE_SUCCESS,
    title: PAYMENT_STATUS_TEXT[EPaymentStatus.PURCHASE_SUCCESS],
  },
  {key: EPaymentStatus.PURCHASE_FAIL, title: PAYMENT_STATUS_TEXT[EPaymentStatus.PURCHASE_FAIL]},
  {key: EPaymentStatus.CANCEL_SUCCESS, title: PAYMENT_STATUS_TEXT[EPaymentStatus.CANCEL_SUCCESS]},
];

const SEARCH_SELECT_LIST: TSelectBoxItem[] = [
  ESearchType.USER_KEY,
  ESearchType.VEHICLE_ID,
  ESearchType.USER_ID,
  ESearchType.PHONE,
  ESearchType.TRIP_ID,
  ESearchType.TRIP_ORDER_ID,
  ESearchType.COMPANY_TRIP_ID,
].map((key) => ({key, title: SEARCH_TYPE_TEXT[key]}));

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호'},
  {title: '결제일시', width: 150},
  {title: 'UserKey'},
  {title: 'UserId'},
  {title: '제휴사명'},
  {title: '기기\n시리얼넘버'},
  {title: '이용시작시각', width: 150},
  {title: '이용종료시각', width: 150},
  {title: '주행시간(s)'},
  {title: '주행거리(m)'},
  {title: '최종결제요금', align: 'right'},
  {title: '포인트'},
  {title: '결제타입'},
  {title: '결제상태', width: 80},
];

const PAGING_COUNT = 20;

const UsageHistoryPage = () => {
  const {show: showAlert} = useToastContext();
  const [company, setCompany] = useState(COMPANY_SELECT_LIST[0]);
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS_SELECT_LIST[0]);
  const [searchType, setSearchType] = useState(SEARCH_SELECT_LIST[0]);
  const [searchValue, setSearchValue] = useState('');
  const [isDataPrepared, setDataPrepared] = useState(false);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: subDays(today, 7),
    endDate: today,
  });

  const {fetchApi, loading, response, resetResponse} = useApi<TTripListResponse>();

  const handleClickId = useCallback((id) => {
    const url = generatePath(Paths.MemberManageDetail, {id});

    window.open(url, '', 'width=1200,height=800');
  }, []);

  const handleClickPaymentStatus = useCallback((id) => {
    const url = generatePath(Paths.PaymentDetail, {id});

    window.open(url, '', 'width=1500,height=800');
  }, []);

  const handleSelectCompany = useCallback((c) => {
    setCompany(c);
    setDataPrepared(true);
  }, []);

  const getList = useCallback(
    (page: number) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        showAlert(ErrorMessage.VALIDATE_START_END);
        return;
      }

      fetchApi(() =>
        api.getTripList({
          page,
          size: PAGING_COUNT,
          startDate,
          endDate,
          company: company.key as ECompanyType,
          status: paymentStatus.key as EPaymentStatus,
          ...(searchValue && {
            searchType: searchType.key as ESearchType,
            searchValue,
          }),
        })
      );
    },
    [fetchApi, company.key, paymentStatus.key, dateRange, searchType, searchValue, showAlert]
  );

  const handleDownload = useCallback(
    (reason: string) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        return Promise.reject();
      }

      return api
        .getTripListExport({
          startDate,
          endDate,
          searchValue,
          searchType: searchType.key as ESearchType,
          company: company.key as ECompanyType,
          status: paymentStatus.key as EPaymentStatus,
          excelReason: reason,
        })
        .then(downloadExcel);
    },
    [dateRange, company, paymentStatus, searchType, searchValue]
  );

  useEffect(() => {
    if (isDataPrepared) {
      getList(1);
    }
  }, [isDataPrepared]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '이용일',
              value: (
                <AdminDateRangePicker
                  startDate={dateRange?.startDate}
                  endDate={dateRange?.endDate}
                  onDateChange={setDateRange}
                />
              ),
              colSpan: 3,
            },
          ],
          [
            {
              key: '제휴사',
              value: <AdminCompanySelectBox onSelect={handleSelectCompany} />,
            },
            {
              key: '결제상태',
              value: (
                <AdminSelectBox
                  selectList={PAYMENT_STATUS_SELECT_LIST}
                  currentItem={paymentStatus}
                  testId="결제상태"
                  onClickItem={setPaymentStatus}
                />
              ),
            },
          ],
          [
            {
              key: '검색어',
              value: (
                <Box sx={{display: 'flex', alignItems: 'end'}}>
                  <AdminSelectBox
                    selectList={SEARCH_SELECT_LIST}
                    currentItem={searchType}
                    onClickItem={setSearchType}
                    testId="검색어"
                  />
                  <TextField
                    hiddenLabel
                    id="search-input"
                    placeholder="값을 입력해주세요."
                    variant="standard"
                    size="small"
                    margin="none"
                    value={searchValue}
                    sx={{mx: 1, width: 480}}
                    onChange={(e) => setSearchValue(e.target.value)}
                    data-cy="search-value"
                  />
                </Box>
              ),
              colSpan: 3,
            },
          ],
        ]}
      />

      <LoadingButton
        loading={!!loading && !response}
        variant="contained"
        sx={{width: 80, my: 2}}
        onClick={() => {
          resetResponse();
          getList(1);
        }}
        data-cy="search"
      >
        검색
      </LoadingButton>

      {response?.data && (
        <>
          <AdminExcelDownButton onDownload={handleDownload} />
          <PagingTable
            loading={!!loading}
            headerData={TABLE_HEADER_LIST}
            tableData={response.data.content.map((c) => [
              c.rowNum,
              getSafeDateFormat(c.updDateTime),
              c.userKey,
              <Button onClick={() => handleClickId(c.userId)}>{c.userId}</Button>,
              COMPANY_TEXT[c.company],
              c.vehicleId,
              getSafeDateFormat(c.tripStartDateTime),
              getSafeDateFormat(c.tripEndDateTime),
              <Duration duration={c.duration} />,
              setComma(c.distance),
              formatMoney(c.paymentAmount),
              formatMoney(c.pointAmount || 0), // 포인트
              c.paymentType,
              <Button onClick={() => handleClickPaymentStatus(c.tripId)}>
                {c.statusDesc || PAYMENT_STATUS_TEXT[c.status]}
              </Button>,
            ])}
            totalCount={response.data.totalCount || 0}
            pagingCount={PAGING_COUNT}
            onChangePage={(p) => getList(p)}
          />
        </>
      )}
    </Box>
  );
};
export default UsageHistoryPage;
