import {useCallback, useEffect, useMemo} from 'react';
import qs from 'qs';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MemberManageDetail from 'components/memberManage/MemberManageDetail';
import UsageDetail from 'components/memberManage/UsageDetail';
import {getTitleWithEnv} from 'utils/string';

enum ETab {
  MEMBER = 'member',
  USAGE = 'usage',
}

const TAB_LIST = [
  {title: '회원정보', type: ETab.MEMBER},
  {title: '이용내역', type: ETab.USAGE},
];

const PICKER_COLOR = 'grey.700';

const MemberManageDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const tab = useMemo(
    () => qs.parse(location.search, {ignoreQueryPrefix: true}).type as ETab,
    [location.search]
  );

  const handleClickTab = useCallback(
    (type) => {
      navigate(`${location.pathname}?type=${type}`, {replace: true});
    },
    [navigate, location]
  );

  useEffect(() => {
    if (!location.search || !TAB_LIST.some((t) => t.type === tab)) {
      navigate(`${location.pathname}?type=${TAB_LIST[0].type}`, {replace: true});
    }
  }, []);

  return (
    <Box p={3} sx={{overflowY: 'scroll', height: 'calc(100vh - 24px)'}}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('회원상세정보')}
      </Typography>
      <ButtonGroup variant="contained" color="inherit" size="small" disableElevation>
        {TAB_LIST.map((t) => {
          const isSelected = location.search.includes(t.type);

          return (
            <Button
              key={t.title}
              size="medium"
              sx={{my: 2, borderColor: PICKER_COLOR}}
              variant={isSelected ? 'contained' : undefined}
              color={isSelected ? 'primary' : undefined}
              onClick={() => handleClickTab(t.type)}
            >
              {t.title}
            </Button>
          );
        })}
      </ButtonGroup>
      <Box>
        {tab === ETab.MEMBER && <MemberManageDetail userId={param.id} />}
        {tab === ETab.USAGE && <UsageDetail userId={param.id} />}
      </Box>
    </Box>
  );
};

export default MemberManageDetailPage;
