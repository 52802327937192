import Alert, {AlertColor} from '@mui/material/Alert';
import Snackbar, {SnackbarOrigin} from '@mui/material/Snackbar';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type TOptions = SnackbarOrigin & {
  type: AlertColor;
  duration: number;
};

type TContext = {
  visible: boolean;
  show: (message?: string, options?: Partial<TOptions>) => void;
  hide: VoidFunction;
};

export const ToastContext = createContext<TContext>({
  visible: false,
  show: () => {},
  hide: () => {},
});

const DEFAULT_DURATION = 3000;
const DEFAULT_ERROR_MESSAGE = '에러가 발생했습니다.';
const DEFAULT_POSITION: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};
const DEFAULT_TYPE: AlertColor = 'error';

const DEFAULT_OPTIONS = {
  ...DEFAULT_POSITION,
  type: DEFAULT_TYPE,
  duration: DEFAULT_DURATION,
};

type TProps = PropsWithChildren<any>;

export const ToastProvider = ({children}: TProps) => {
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(DEFAULT_ERROR_MESSAGE);
  const [toastOptions, setToastOptions] = useState<TOptions>(DEFAULT_OPTIONS);

  const hide = useCallback(() => setOpen(false), []);
  const show = useCallback(
    (message?: string, options?: Partial<TOptions>) => {
      hide();

      setToastOptions((prev) => ({
        ...prev,
        ...options,
      }));

      if (message) {
        setToastMessage(message);
      }

      setOpen(true);
    },
    [hide]
  );

  useEffect(() => {
    if (!hide) {
      setToastMessage(DEFAULT_ERROR_MESSAGE);
      setToastOptions(DEFAULT_OPTIONS);
    }
  }, [hide]);

  const value = useMemo(() => ({visible: open, show, hide}), [open, show, hide]);

  return (
    <ToastContext.Provider value={value}>
      <Snackbar
        open={open}
        autoHideDuration={toastOptions.duration}
        onClose={hide}
        anchorOrigin={{
          vertical: toastOptions.vertical,
          horizontal: toastOptions.horizontal,
        }}
      >
        <Alert severity={toastOptions.type}>{toastMessage}</Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
