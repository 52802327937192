import {ReactNode} from 'react';
import {FormControl, RadioGroup, Box, FormControlLabel, Radio} from '@mui/material';

type TProps = {
  list: Array<{
    key: number | string;
    title: string;
    Component?: ReactNode;
  }>;
  value?: string;
  testId?: string;
  onChange?: (type) => void;
};

const PopupFormGroup = ({list, value, testId, onChange}: TProps) => {
  return (
    <FormControl size="small" margin="none" sx={{px: 1}}>
      <RadioGroup row value={value} onChange={(v) => onChange?.(v.currentTarget.value)}>
        {list.map((s) => (
          <Box key={s.key} sx={{display: 'flex'}}>
            <FormControlLabel
              value={s.key}
              control={<Radio data-cy={`${testId}-radio-${s.key}`} />}
              label={s.title}
            />
            {s?.Component}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PopupFormGroup;
