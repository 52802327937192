import {useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import EventPagePopup from 'components/notice/EventPagePopup';
import useApi from 'hooks/useApi';
import {TEventPageUpdateParams, TEventPageListResponse} from 'types/api';
import api from 'utils/api';
import {getTitleWithEnv} from 'utils/string';

const UpdateEventPagePopup = () => {
  const param = useParams();
  const {fetchApi, response, error, loading} = useApi<TEventPageListResponse>();

  useEffect(() => {
    if (!param.id) {
      return;
    }

    fetchApi(() =>
      api.getEventPageDetail({
        id: Number(param.id),
      })
    );
  }, []);

  const handleSave = useCallback(
    (data: TEventPageUpdateParams) => {
      api
        .postEventPageDetail({
          id: Number(param.id),
          ...data,
        })
        .then(() => {
          window.alert('이벤트 페이지 업데이트을 완료하였습니다.');
          window.opener?.location.reload();
          window.close();
        })
        .catch(() => {
          window.location.reload();
        });
    },
    [param.id]
  );

  if (error) {
    return null;
  }

  return (
    <Box p={3} sx={{overflowY: 'scroll', height: '80vh'}}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('이벤트 페이지 업데이트')}
      </Typography>

      {loading ? null : (
        <Box my={3}>
          <EventPagePopup {...response?.data} onSave={handleSave} />
        </Box>
      )}
    </Box>
  );
};

export default UpdateEventPagePopup;
