import {useMemo} from 'react';
import {TextField} from '@mui/material';
import PopupFormGroup from './PopupFormGroup';
import {ELandingUrlType, LANDING_URL_TITLE} from 'constants/App';

type TProps = {
  landingUrl?: string;
  onChange?: (url: string) => void;
  landingUrlType?: ELandingUrlType;
  onChangeType?: (type: ELandingUrlType) => void;
  testId?: string;
};

const PopupDetailLink = ({landingUrl, landingUrlType, testId, onChangeType, onChange}: TProps) => {
  const LANDING_URL_LIST = useMemo(
    () =>
      Object.values(ELandingUrlType).map((key) => ({
        key,
        title: LANDING_URL_TITLE[key],
        Component:
          key === ELandingUrlType.HAS_URL ? (
            <TextField
              hiddenLabel
              id="search-landing-link"
              placeholder="상세링크를 입력하세요"
              variant="standard"
              size="small"
              margin="none"
              value={landingUrl}
              sx={{mx: 1, width: 480, display: 'flex', justifyContent: 'center'}}
              onChange={(e) => onChange?.(e.target.value)}
              disabled={landingUrlType === ELandingUrlType.NONE}
              data-cy={`${testId}-input`}
            />
          ) : null,
      })),
    [landingUrl, landingUrlType, onChange, testId]
  );

  return (
    <PopupFormGroup
      testId={testId}
      list={LANDING_URL_LIST}
      value={landingUrlType}
      onChange={onChangeType}
    />
  );
};

export default PopupDetailLink;
