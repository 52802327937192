import {useCallback, useEffect, useState} from 'react';
import {subMonths} from 'date-fns';
import {generatePath} from 'react-router-dom';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import api from 'utils/api';
import useApi from 'hooks/useApi';

import {useToastContext} from 'context/ToastContext';
import AdminSelectBox from 'components/AdminSelectBox';
import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';
import VerticalKeyTable from 'components/VerticalKeyTable';
import PagingTable from 'components/PagingTable';
import {TTableHeader} from 'components/HorizontalKeyTable';
import AdminImagePopover from 'components/AdminImagePopover';

import {EInUseStatus, IN_USE_SELECT_LIST, IN_USE_STATUS_TEXT, today} from 'constants/App';
import {ErrorMessage} from 'constants/Error';
import Paths from 'constants/Paths';

import {TEventPageListResponse} from 'types/api';
import {getSafeDateFormat} from 'utils/date';
import {setComma} from 'utils/number';

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호'},
  {title: 'ID'},
  {title: '제목'},
  {title: '게시기간', width: 150},
  {title: '게시여부'},
  {title: '등록일시'},
  {title: '최종등록일시'},
  {title: '페이지 url'},
  {title: '등록자'},
];

const PAGING_COUNT = 20;

const openPopup = (path) => {
  window.open(path, '', 'width=1100,height=800');
};

const NoticeEventPage = () => {
  const {show: showAlert} = useToastContext();
  const {fetchApi, loading, response, resetResponse} = useApi<TEventPageListResponse>();
  const [inUseStatus, setInUseStatus] = useState(IN_USE_SELECT_LIST[0]);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: subMonths(today, 3),
    endDate: today,
  });

  const getList = useCallback(
    (page: number) => {
      if (!dateRange?.startDate || !dateRange?.endDate) {
        showAlert(ErrorMessage.VALIDATE_START_END);
        return;
      }

      fetchApi(() =>
        api.getEventPageList({
          page,
          startDate: dateRange.startDate as Date,
          endDate: dateRange.endDate as Date,
          isUse: inUseStatus.value,
        })
      );
    },
    [fetchApi, dateRange, showAlert, inUseStatus]
  );

  const handleClickCreate = useCallback(() => {
    openPopup(Paths.CreateEventPagePopup);
  }, []);

  const handleClickItem = useCallback((id) => {
    const url = generatePath(Paths.UpdateEventPagePopup, {id});

    openPopup(url);
  }, []);

  useEffect(() => {
    getList(1);
  }, []);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '게시기간',
              align: 'left',
              value: (
                <AdminDateRangePicker
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onDateChange={setDateRange}
                />
              ),
            },
          ],
          [
            {
              key: '게시 여부',
              align: 'left',
              value: (
                <AdminSelectBox
                  selectList={IN_USE_SELECT_LIST}
                  currentItem={inUseStatus}
                  onClickItem={setInUseStatus}
                />
              ),
            },
          ],
        ]}
      />

      <LoadingButton
        loading={!!loading && !response}
        variant="contained"
        sx={{width: 80, my: 2}}
        onClick={() => {
          resetResponse();
          getList(1);
        }}
      >
        검색
      </LoadingButton>

      {response?.data && (
        <>
          <Button
            size="small"
            variant="outlined"
            color="success"
            sx={{my: 2, alignSelf: 'flex-end'}}
            onClick={handleClickCreate}
          >
            이벤트 새로 만들기
          </Button>
          <PagingTable
            loading={!!loading}
            headerData={TABLE_HEADER_LIST}
            totalCount={response?.data.totalCount || 0}
            pagingCount={PAGING_COUNT}
            tableData={(response?.data?.content || []).map((c) => [
              setComma(c.rowNum),
              c.eventPageId,
              <AdminImagePopover
                imgUrl={c.imgUrl}
                title={c.title}
                onClick={() => handleClickItem(c.eventPageId)}
              />,
              `${getSafeDateFormat(c.startDateTime)} ~ ${getSafeDateFormat(c.endDateTime)}`,
              c.isUse
                ? IN_USE_STATUS_TEXT[EInUseStatus.IN_USE]
                : IN_USE_STATUS_TEXT[EInUseStatus.NOT_IN_USE],
              getSafeDateFormat(c.regDateTime),
              getSafeDateFormat(c.updDateTime),
              `tmap://kickboard?extra=${encodeURIComponent(`landing_/event/${c.eventPageId}`)}`,
              c.accountName,
            ])}
            onChangePage={getList}
          />
        </>
      )}
    </Box>
  );
};

export default NoticeEventPage;
