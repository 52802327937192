import {useNavigate} from 'react-router-dom';
import CommonError from './CommonError';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <CommonError
      title="잘못된 접근입니다."
      description="유효한 url로 접근해주세요."
      buttonText={window.opener ? '닫기' : '메인으로 가기'}
      onClick={() => {
        window.opener ? window.close() : navigate('/');
      }}
    />
  );
};

export default NotFound;
