import {useState, PropsWithChildren} from 'react';
import Box from '@mui/material/Box';
import Title from './Title';
import Header from './Header';
import Sidebar from './Sidebar';

type TProps = PropsWithChildren<{}>;

const CommonLayout = ({children}: TProps) => {
  const [isSidebarActive, setSidebarActive] = useState<boolean>(true);

  return (
    <>
      <Header />
      <Box sx={{display: 'flex'}}>
        <Sidebar active={isSidebarActive} />
        <Box sx={{flexGrow: 1, overflowY: 'scroll', height: 'calc(100vh - 48px)'}}>
          <Box sx={{position: 'sticky', top: 0, background: 'white', zIndex: 10}}>
            <Title onToggleSidebar={() => setSidebarActive((prev) => !prev)} />
          </Box>
          <Box px={3} py={2}>
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CommonLayout;
