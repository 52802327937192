import {useCallback} from 'react';
import {Box, Typography} from '@mui/material';
import {TTextBannerUpdateParams} from 'types/api';
import api from 'utils/api';
import {getTitleWithEnv} from 'utils/string';
import TextBannerPopup from 'components/notice/TextBannerPopup';

const CreateTextBannerPopup = () => {
  const handleSave = useCallback((data: TTextBannerUpdateParams) => {
    api
      .postTextBannerDetail(data)
      .then(() => {
        window.alert('텍스트 배너 생성을 완료하였습니다.');
        window.opener?.location.reload();
        window.close();
      })
      .catch(() => {
        window.location.reload();
      });
  }, []);

  return (
    <Box p={3}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('텍스트 배너 새로 만들기')}
      </Typography>

      <Box my={3}>
        <TextBannerPopup onSave={handleSave} />
      </Box>
    </Box>
  );
};

export default CreateTextBannerPopup;
