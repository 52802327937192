import axios from 'axios';
import qs from 'qs';
import Paths from 'constants/Paths';
import {EAuthErrorCode, EErrorCode} from 'constants/Error';
import {EStorageKey} from 'constants/Storage';
import {APP_ENV, EServerEnv} from 'constants/Env';
import {
  TDateRangeParam,
  TPopupParam,
  TPopupDetailParam,
  TPopupUpdateParams,
  TPaymentDetailParams,
  TPostRepayment,
  TPostPenaltyParams,
  TPostTotalRefund,
  TPostUserStatusParams,
  TSettlementExportParams,
  TSettlementParams,
  TSignInParam,
  TSignInResponse,
  TTripListExportParams,
  TTripListParams,
  TUnsettlementExportParams,
  TUnsettlementParams,
  TUploadImageParams,
  TUserDetailParams,
  TUserExportParams,
  TUserParams,
  TUserStatisticsExportParams,
  TUserStatisticsParams,
  TUserTripDetailParams,
  TTextBannerUpdateParams,
  TEventPageUpdateParams,
  TUserResponse,
  TUserDetailResponse,
  TUserTripDetailResponse,
  TPaymentDetailResponse,
  TSettlementResponse,
  TTripListResponse,
  TUnsettlementResponse,
  EUserStatus,
  TResponse,
  THomePopupResponse,
  THomePopupDetailResponse,
  TTextBannerResponse,
  TEventPageListResponse,
} from 'types/api';
import normalizeError from './normalizeError';
import {getSafeDateFormat} from './date';
import {localStorageStore} from 'store/stroageStore';
import loginDataStore from 'store/loginDataStore';

// 엑셀 다운로드 시 타임아웃이 나서 1분으로 수정
const NETWORK_TIMEOUT = 60 * 1000;
export const DEFAULT_PAGE_SIZE = 20;

const API_BASE_URL_MAP: Record<EServerEnv, string> = {
  [EServerEnv.PROD]: 'https://pmsapi.tmapadmin.com/',
  [EServerEnv.STAGE]: 'https://pmsapi-stg.tmapadmin.com/',
  [EServerEnv.DEV]: 'https://pmsapi-dev.tmapadmin.com/',
};

const API_BASE_URL = API_BASE_URL_MAP[APP_ENV];

const fetcher = axios.create({
  baseURL: API_BASE_URL,
  timeout: NETWORK_TIMEOUT,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
  },
});

fetcher.interceptors.request.use((config) => {
  const authToken = localStorageStore.getState()[EStorageKey.LOGIN_TOKEN];

  config.headers = {
    ...config.headers,
    // https://github.com/axios/axios/issues/4193
    // @ts-ignore
    common: {
      // @ts-ignore
      ...(config.headers?.common || {}),
      ...(authToken && {
        Authorization: `Bearer ${authToken}`,
      }),
    },
  };

  return config;
});

fetcher.interceptors.response.use(
  (response) => (response.config.responseType === 'blob' ? response : response.data),
  (e) => {
    const error = normalizeError(e);

    if (Object.values(EAuthErrorCode).includes(error.code as EAuthErrorCode)) {
      if (error.code !== EAuthErrorCode.AUTH_ACCESS_DENIED) {
        localStorageStore.remove(EStorageKey.LOGIN_TOKEN);
        loginDataStore.logout();
      }

      window.alert(error.message);
      if (window.location.pathname !== Paths.Login) {
        window.location.replace(Paths.Login);
      }

      // 에러 처리를 공통으로 할 때는, 에러 객체를 undefined 로 return
      return Promise.reject(undefined);
    }

    window.alert(error.message);

    if (error.code === EErrorCode.ECONNABORTED) {
      return Promise.reject(undefined);
    }

    return Promise.reject(error);
  }
);

export const parseApiDate = ({startDate, endDate}: TDateRangeParam) => ({
  startDate: getSafeDateFormat(startDate, 'yyyy-MM-dd'),
  endDate: getSafeDateFormat(endDate, 'yyyy-MM-dd'),
});

const API_HOST_MAP: Record<EServerEnv, string> = {
  [EServerEnv.PROD]: 'https://pm.tmobiapi.com',
  [EServerEnv.STAGE]: 'https://pm-stg.tmobiapi.com',
  [EServerEnv.DEV]: 'https://pm-dev.tmobiapi.com',
};

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=450340188
const postSignIn = async (params: TSignInParam): Promise<TResponse<TSignInResponse>> => {
  const result: any = await fetcher.post<TSignInResponse>(
    '/api/account/signin',
    qs.stringify(params),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  localStorageStore.setValue(EStorageKey.LOGIN_TOKEN, result.data.accessToken);

  return result;
};

const getUserStatisticsList = (
  params: TUserStatisticsParams
): Promise<TResponse<TUserStatisticsParams>> => {
  return fetcher.get('/api/admin/userstatistics/item', {
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUserStatisticsListExport = (params: TUserStatisticsExportParams) => {
  return fetcher.get('/api/admin/userstatistics/item/export', {
    responseType: 'blob',
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUserList = (params: TUserParams): Promise<TResponse<TUserResponse>> => {
  return fetcher.get('/api/admin/user/item', {
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUserListExport = (params: TUserExportParams) => {
  return fetcher.get('/api/admin/user/item/export', {
    responseType: 'blob',
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUserDetail = ({userId}: TUserDetailParams): Promise<TResponse<TUserDetailResponse>> => {
  return fetcher.get(`/api/admin/user/${userId}`);
};

const getUserTripDetail = ({
  userId,
  page,
  size,
}: TUserTripDetailParams): Promise<TResponse<TUserTripDetailResponse>> => {
  return fetcher.get(`/api/admin/user/${userId}/tripOrder`, {params: {page, size}});
};

const getPaymentDetail = ({
  tripId,
}: TPaymentDetailParams): Promise<TResponse<TPaymentDetailResponse>> => {
  return fetcher.get(`/api/admin/trip/${tripId}/detail`);
};

const getSettlementList = (params: TSettlementParams): Promise<TResponse<TSettlementResponse>> => {
  return fetcher.get('/api/admin/settlement/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getSettlementListExport = (params: TSettlementExportParams) => {
  return fetcher.get('/api/admin/settlement/item/export', {
    responseType: 'blob',
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getTripList = (params: TTripListParams): Promise<TResponse<TTripListResponse>> => {
  return fetcher.get('/api/admin/tripOrder/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getTripListExport = (params: TTripListExportParams) => {
  return fetcher.get('/api/admin/tripOrder/item/export', {
    responseType: 'blob',
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUnsettledList = (
  params: TUnsettlementParams
): Promise<TResponse<TUnsettlementResponse>> => {
  return fetcher.get('/api/admin/settlement/unpaid/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getUnsettledListExport = (params: TUnsettlementExportParams) => {
  return fetcher.get('/api/admin/settlement/unpaid/item/export', {
    responseType: 'blob',
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const postUserStatus = (
  params: TPostUserStatusParams
): Promise<Promise<TResponse<EUserStatus>>> => {
  return fetcher.post('/api/admin/user/status', params);
};

const postPenalty = (params: TPostPenaltyParams) => {
  return fetcher.post('/api/admin/tripOrder/penalty', params);
};

const postTotalRefund = (params: TPostTotalRefund) => {
  return fetcher.post('/api/admin/tripOrder/cancel', params);
};

const postRepayment = (params: TPostRepayment) => {
  return fetcher.post('/api/admin/tripOrder/repayment', params);
};

const getHomePopupList = (params: TPopupParam): Promise<TResponse<THomePopupResponse>> => {
  return fetcher.get('/api/admin/popup/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getHomePopupDetail = (
  params: TPopupDetailParam
): Promise<TResponse<THomePopupDetailResponse>> => {
  return fetcher.get(`/api/admin/popup/${params.id}/detail`);
};

const postHomePopupDetail = (params: TPopupUpdateParams) => {
  return fetcher.post('/api/admin/popup/save', {
    popupId: params.id,
    ...params,
  });
};

const uploadImage = (params: TUploadImageParams): Promise<TResponse<string>> => {
  return fetcher.post('/api/admin/image/upload', params, {
    headers: {
      // https://github.com/axios/axios#-automatic-serialization-to-formdata
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getTextBannerList = (params: TPopupParam): Promise<TResponse<TTextBannerResponse>> => {
  return fetcher.get('/api/admin/textBanner/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getTextBannerDetail = (
  params: TPopupDetailParam
): Promise<TResponse<TTextBannerResponse>> => {
  return fetcher.get(`/api/admin/textBanner/${params.id}/detail`);
};

const postTextBannerDetail = (params: TTextBannerUpdateParams) => {
  return fetcher.post('/api/admin/textBanner/save', {
    bannerId: params.id,
    ...params,
  });
};

const getEventPageList = (params: TPopupParam): Promise<TResponse<TEventPageListResponse>> => {
  return fetcher.get('/api/admin/eventPage/item', {
    params: {
      ...params,
      ...parseApiDate(params),
    },
  });
};

const getEventPageDetail = (
  params: TPopupDetailParam
): Promise<TResponse<TEventPageListResponse>> => {
  return fetcher.get(`/api/admin/eventPage/${params.id}/detail`);
};

const postEventPageDetail = (params: TEventPageUpdateParams) => {
  return fetcher.post('/api/admin/eventPage/save', {
    eventPageId: params.id,
    ...params,
  });
};

const postOutstandingAmountPurchase = (userKey: string, tripOrderId: string) => {
  return fetcher.post(
    `${API_HOST_MAP[APP_ENV]}/api/tripOrder/${tripOrderId}/purchase_byadmin`,
    undefined,
    {
      headers: {
        userKey,
      },
    }
  );
};

const getAccountInfo = () => {
  const loginData = loginDataStore.getState();
  if (loginData.accountId) {
    return {data: loginData};
  }

  return fetcher.get('/api/admin/account/info');
};

const logout = () => {
  return fetcher.get('/api/admin/account/logout');
};

const api = {
  postSignIn,
  getUserStatisticsList,
  getUserStatisticsListExport,
  getUserList,
  getUserListExport,
  getUserDetail,
  getUserTripDetail,
  getPaymentDetail,
  getSettlementList,
  getSettlementListExport,
  getUnsettledList,
  getUnsettledListExport,
  getTripList,
  getTripListExport,
  postUserStatus,
  postPenalty,
  postTotalRefund,
  postRepayment,
  getHomePopupList,
  getHomePopupDetail,
  postHomePopupDetail,
  uploadImage,
  getTextBannerList,
  getTextBannerDetail,
  postTextBannerDetail,
  getEventPageList,
  getEventPageDetail,
  postEventPageDetail,
  postOutstandingAmountPurchase,
  getAccountInfo,
  logout,
};

export default api;
