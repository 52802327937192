import {useState, useCallback} from 'react';
import {subMonths, subYears} from 'date-fns';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import AdminExcelDownButton from 'components/AdminExcelDownButton';
import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';
import {TSelectBoxItem} from 'components/AdminSelectBox';
import AdminCompanySelectBox from 'components/AdminCompanySelectBox';
import {TTableHeader} from 'components/HorizontalKeyTable';
import PagingTable from 'components/PagingTable';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {COMPANY_LIST, COMPANY_TEXT, PAYMENT_STATUS_TEXT, today} from 'constants/App';
import {ErrorMessage} from 'constants/Error';
import {useToastContext} from 'context/ToastContext';
import useApi from 'hooks/useApi';
import {TUnsettlementResponse, ECompanyType} from 'types/api';
import api from 'utils/api';
import {getSafeDateFormat} from 'utils/date';
import {formatMoney} from 'utils/number';
import {downloadExcel} from 'utils/file';

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호', width: 30},
  {title: '제휴사명'},
  {title: '업체 주행 Id'},
  {title: '결제일시'},
  {title: '결제구분'},
  {title: '총 이용요금', align: 'right'},
  {title: '총 할인금액', align: 'right'},
  {title: '총 패널티', align: 'right'},
  {title: '결제금액', align: 'right'},
  {title: '결제금액\n수수료', align: 'right'},
  {title: '정산대상\n할인금액', align: 'right'},
  {title: '할인금액\n수수료', align: 'right'},
  {title: '수기정산대행 금액', align: 'right'},
];

const PAGING_COUNT = 20;
const MIN_DATE = subYears(today, 1);

const CompanyUnsettledPage = () => {
  const {show: showAlert} = useToastContext();
  const [company, setCompany] = useState<TSelectBoxItem>(COMPANY_LIST[0]);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: subMonths(today, 3),
    endDate: today,
  });
  const {fetchApi, loading, response, resetResponse} = useApi<TUnsettlementResponse>();

  const getList = useCallback(
    (page: number) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        showAlert(ErrorMessage.VALIDATE_START_END);
        return;
      }

      fetchApi(() =>
        api.getUnsettledList({
          page,
          size: PAGING_COUNT,
          company: company.key as ECompanyType,
          startDate,
          endDate,
        })
      );
    },
    [fetchApi, company.key, dateRange, showAlert]
  );

  const handleDownload = useCallback(
    (reason: string) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        return Promise.reject();
      }

      return api
        .getUnsettledListExport({
          startDate,
          endDate,
          company: company.key as ECompanyType,
          excelReason: reason,
        })
        .then(downloadExcel);
    },
    [dateRange, company]
  );

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '제휴사',
              value: <AdminCompanySelectBox onSelect={setCompany} />,
            },
          ],
          [
            {
              key: '결제일',
              value: (
                <AdminDateRangePicker
                  minDate={MIN_DATE}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onDateChange={setDateRange}
                />
              ),
            },
          ],
        ]}
      />

      <LoadingButton
        loading={!!loading && !response}
        variant="contained"
        sx={{width: 80, my: 2}}
        onClick={() => {
          resetResponse();
          getList(1);
        }}
      >
        검색
      </LoadingButton>

      {response?.data && (
        <>
          <AdminExcelDownButton onDownload={handleDownload} />
          <PagingTable
            loading={!!loading}
            headerData={TABLE_HEADER_LIST}
            tableData={response.data.content.map((c) => [
              c.rowNum,
              COMPANY_TEXT[c.companyCode],
              c.companyTripId, // 주행 Id
              getSafeDateFormat(c.baseDateTime),
              c.statusName || PAYMENT_STATUS_TEXT[c.status], // 결제구분
              formatMoney(c.totalAmount), // 총 이용요금
              formatMoney(c.discountAmount), // 총 할인금액
              formatMoney(c.penaltyAmount), // 총 패널티
              formatMoney(c.paymentAmount), // 결제금액
              formatMoney(c.paymentExecutionCommission), // 결제금액수수료
              formatMoney(c.discountExecutionAmount), // 정산대상할인금액
              formatMoney(c.discountExecutionCommission), // 할인금액 수수료
              formatMoney(c.manualDiscountAmount), // 수기정산 금액
            ])}
            totalCount={response.data.totalCount || 0}
            pagingCount={PAGING_COUNT}
            onChangePage={getList}
          />
        </>
      )}
    </Box>
  );
};
export default CompanyUnsettledPage;
