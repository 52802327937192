import {ReactNode} from 'react';
import Box from '@mui/material/Box';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useToastContext} from 'context/ToastContext';

type TProps = {
  value: ReactNode;
};

const AdminCopyToClipboard = ({value}: TProps) => {
  const {show: showToast} = useToastContext();

  return (
    <>
      {['string', 'number'].includes(typeof value) ? (
        <CopyToClipboard
          text={value}
          onCopy={() =>
            showToast(`${value} 복사되었습니다.`, {
              vertical: 'bottom',
              horizontal: 'right',
              type: 'info',
              duration: 1000,
            })
          }
        >
          <Box sx={{width: '100%', height: '100%'}}>{value}</Box>
        </CopyToClipboard>
      ) : (
        value
      )}
    </>
  );
};

export default AdminCopyToClipboard;
