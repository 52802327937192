export enum EServerEnv {
  PROD = 'PROD',
  STAGE = 'STAGE',
  DEV = 'DEV',
}

export const APP_ENV = process.env.REACT_APP_ENV as EServerEnv;

export const isLocalEnv = process.env.NODE_ENV === 'development';
export const isProdServer = APP_ENV === EServerEnv.PROD;

export const ENV_NAME = isLocalEnv
  ? 'LOCAL'
  : {
      [EServerEnv.DEV]: 'DEV',
      [EServerEnv.STAGE]: 'STG',
      [EServerEnv.PROD]: '',
    }[APP_ENV];
