import {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {COMPANY_LIST, COMPANY_TEXT, EUserRole} from 'constants/App';
import AdminSelectBox, {TSelectBoxItem} from './AdminSelectBox';
import useLoginDataStore from 'hooks/useLoginDataStore';

type TProps = {
  onSelect?: (company: TSelectBoxItem) => void;
  selectList?: TSelectBoxItem[];
};

export const COMPANY_SELECT_LIST: TSelectBoxItem[] = [
  {key: undefined, title: '전체'},
  ...COMPANY_LIST,
];

const AdminCompanySelectBox = ({onSelect, selectList = COMPANY_SELECT_LIST}: TProps) => {
  const {companyCode, role} = useLoginDataStore();
  const [company, setCompany] = useState<TSelectBoxItem>(selectList[0]);

  const handleClickItem = useCallback(
    (c: TSelectBoxItem) => {
      setCompany(c);
      onSelect?.(c);
    },
    [onSelect]
  );

  useEffect(() => {
    if (role === EUserRole.PARTNER && companyCode) {
      const item = selectList.find((s) => s.key === companyCode);

      item && onSelect?.(item);
    } else {
      onSelect?.(selectList[0]);
    }
  }, [role, companyCode, onSelect, selectList]);

  if (role === EUserRole.PARTNER) {
    if (!companyCode) {
      return null;
    }

    return <Box sx={{mx: 1}}>{COMPANY_TEXT[companyCode]}</Box>;
  }

  return (
    <AdminSelectBox
      currentItem={company}
      selectList={selectList}
      testId="제휴사"
      onClickItem={handleClickItem}
    />
  );
};

export default AdminCompanySelectBox;
