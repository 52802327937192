import {Box} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';

const PopupImagePreview = ({imgUrl, isOrigin}) => {
  const [error, hasError] = useState(false);

  const handleDoubleClick = useCallback(() => {
    if (isOrigin) {
      window.open(imgUrl);
    }
  }, [isOrigin, imgUrl]);

  useEffect(() => {
    if (imgUrl) {
      hasError(false);
    }
  }, [imgUrl]);

  return (
    <Box sx={{border: '1px solid black', mr: 1, maxHeight: 300, position: 'relative'}}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          px: 1,
          py: 0.5,
          minWidth: 30,
          background: 'yellow',
        }}
      >
        {isOrigin ? 'AS-IS' : 'TO-BE'}
      </Box>
      {error ? (
        <Box sx={{p: 5}}>오류</Box>
      ) : (
        <img
          src={imgUrl}
          alt="이미지"
          height={300}
          onDoubleClick={handleDoubleClick}
          onError={() => hasError(true)}
        />
      )}
    </Box>
  );
};

export default PopupImagePreview;
