import {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NoticeHomePopup from 'components/notice/HomePopup';
import {getTitleWithEnv} from 'utils/string';
import {THomePopupDetailResponse, TPopupUpdateParams} from 'types/api';
import useApi from 'hooks/useApi';
import api from 'utils/api';

const UpdateNoticeHomePopup = () => {
  const param = useParams();
  const {fetchApi, response, error, loading} = useApi<THomePopupDetailResponse>();

  useEffect(() => {
    if (!param.id) {
      return;
    }

    fetchApi(() =>
      api.getHomePopupDetail({
        id: Number(param.id),
      })
    );
  }, []);

  const handleSave = useCallback(
    (data: TPopupUpdateParams) => {
      api
        .postHomePopupDetail({
          id: Number(param.id),
          ...data,
        })
        .then(() => {
          window.alert('업데이트 성공하였습니다.');
          window.opener?.location.reload();
          window.close();
        })
        .catch(() => {
          window.location.reload();
        });
    },
    [param.id]
  );

  if (error) {
    return null;
  }

  return (
    <Box p={3}>
      <Typography variant="h6" sx={{flexGrow: 1}}>
        {getTitleWithEnv('팝업 업데이트')}
      </Typography>

      {loading ? null : (
        <Box my={3}>
          <NoticeHomePopup {...response?.data} onSave={handleSave} />
        </Box>
      )}
    </Box>
  );
};

export default UpdateNoticeHomePopup;
