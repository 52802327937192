import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {set} from 'date-fns';
import {Box, TextField} from '@mui/material';
import {today} from 'constants/App';
import {TDate} from './AdminDateRangePicker';

type TProps = {
  value: TDate;
  onChange: (date: TDate) => void;
};

const defaultValue = set(today, {
  hours: 0,
  minutes: 0,
  seconds: 0,
});

const AdminTimePicker = ({value, onChange}: TProps) => {
  return (
    <Box sx={{ml: 1}}>
      <TimePicker
        ampm={false}
        openTo="hours"
        views={['hours', 'minutes', 'seconds']}
        inputFormat="HH:mm:ss"
        mask="__:__:__"
        value={value || defaultValue}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};

export default AdminTimePicker;
