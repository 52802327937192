import Box from '@mui/material/Box';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useToastContext} from 'context/ToastContext';
import {parseSecondsToHMS, setComma} from 'utils/number';
import {useMemo} from 'react';

type TProps = {
  duration: number;
  showHMS?: boolean;
};

const Duration = ({duration, showHMS}: TProps) => {
  const {show: showToast} = useToastContext();
  const hms = useMemo(() => parseSecondsToHMS(duration), [duration]);
  const text = useMemo(
    () => `${setComma(duration)}${showHMS ? ` (${hms})` : ''}`,
    [duration, showHMS, hms]
  );

  return (
    <CopyToClipboard
      text={text}
      onCopy={() =>
        showToast(`${text} 복사되었습니다.`, {
          vertical: 'bottom',
          horizontal: 'right',
          type: 'info',
          duration: 1000,
        })
      }
    >
      <Box sx={{width: '100%', height: '100%'}} title={parseSecondsToHMS(duration)}>
        {text}
      </Box>
    </CopyToClipboard>
  );
};

export default Duration;
