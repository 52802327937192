import {Box} from '@mui/material';

import OutstandingAmountForm from 'components/outstandingAmount/OutstandingAmountForm';
import PurchaseTableModal from 'components/outstandingAmount/PurchaseTableModal';
import useOutstandingAmount from 'hooks/outstandingAmount/useOutstandingAmount';
import {useCallback, useState} from 'react';
import {TOutstandingRequest} from 'types/api';

const OutstandingAmountPage = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const {list, setList} = useOutstandingAmount();

  const handleSubmit = useCallback(
    (params: TOutstandingRequest[]) => {
      setList(params);
      setConfirmOpen(true);
    },
    [setList]
  );

  return (
    <Box>
      <OutstandingAmountForm onSubmit={handleSubmit} />
      {confirmOpen && (
        <PurchaseTableModal list={list || []} onClose={() => setConfirmOpen(false)} />
      )}
    </Box>
  );
};

export default OutstandingAmountPage;
