import {useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {TDateRange} from 'components/AdminDateRangePicker';
import {EInUseStatus, ELandingUrlType, IN_USE_STATUS_LIST} from 'constants/App';
import {EUploadImageType, THomePopupItem, TPopupUpdateParams} from 'types/api';
import {getSafeDateFormat, parseDate} from 'utils/date';
import {useToastContext} from 'context/ToastContext';
import api from 'utils/api';
import PopupImageUpload from './PopupImageUpload';
import PopupTimePicker from './PopupTimePicker';
import PopupInput from './PopupInput';
import PopupDetailLink from './PopupDetailLink';
import PopupFormGroup from './PopupFormGroup';
import PopupButtonGroup from './PopupButtonGroup';
import FileUploadContainer from 'components/FileUploadContainer';
import useFileUpload from 'hooks/useFileUpload';

type TProps = Partial<THomePopupItem> & {
  onSave?: (params: TPopupUpdateParams) => void;
};

const NoticeHomePopup = ({onSave, ...props}: TProps = {}) => {
  const {show: showAlert} = useToastContext();

  const [title, setTitle] = useState(props.title || '');
  const [landingUrl, setLandingUrl] = useState(props.landingUrl || '');
  const {uploadedImage, setUploadedImage, imageUrl} = useFileUpload(props.imgUrl);

  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: parseDate(props.startDateTime),
    endDate: parseDate(props.endDateTime),
  });

  const [landingUrlType, setLandingUrlType] = useState<ELandingUrlType>(
    props.landingUrl ? ELandingUrlType.HAS_URL : ELandingUrlType.NONE
  );

  const [inUseStatus, setInUseStatus] = useState<EInUseStatus>(
    props.isUse ? EInUseStatus.IN_USE : EInUseStatus.NOT_IN_USE
  );

  const handleClickSave = useCallback(async () => {
    const img = imageUrl || uploadedImage;

    if (!title || !dateRange.startDate || !dateRange.endDate || !img) {
      showAlert('필수 값을 모두 입력해주세요.');
      return;
    }

    let imgUrl = imageUrl;

    if (uploadedImage) {
      const {data} = await api.uploadImage({
        type: EUploadImageType.POPUP,
        file: uploadedImage,
      });

      imgUrl = data;
    }

    onSave?.({
      title,
      imgUrl,
      landingUrl: landingUrlType === ELandingUrlType.HAS_URL ? landingUrl : undefined,
      isUse: inUseStatus === EInUseStatus.IN_USE ? true : false,
      startDateTime: getSafeDateFormat(dateRange.startDate),
      endDateTime: getSafeDateFormat(dateRange.endDate),
    });
  }, [
    title,
    dateRange,
    uploadedImage,
    showAlert,
    imageUrl,
    inUseStatus,
    landingUrl,
    landingUrlType,
    onSave,
  ]);

  return (
    <FileUploadContainer onUpload={setUploadedImage}>
      <Box>
        <VerticalKeyTable
          tableData={[
            [
              {
                key: '제목',
                align: 'left',
                required: true,
                value: <PopupInput testId="title" title={title} onChange={setTitle} />,
              },
            ],
            [
              {
                key: '게시기간',
                align: 'left',
                required: true,
                value: (
                  <PopupTimePicker
                    testId="dateRange"
                    dateRange={dateRange}
                    onDateChange={setDateRange}
                  />
                ),
              },
            ],
            [
              {
                key: '이미지',
                align: 'left',
                required: true,
                value: (
                  <PopupImageUpload
                    prevImgUrl={props.imgUrl}
                    imgUrl={imageUrl}
                    onUploadImage={setUploadedImage}
                  />
                ),
              },
            ],
            [
              {
                key: '상세링크',
                align: 'left',
                value: (
                  <PopupDetailLink
                    landingUrl={landingUrl}
                    onChange={setLandingUrl}
                    landingUrlType={landingUrlType}
                    onChangeType={setLandingUrlType}
                    testId="detailLink"
                  />
                ),
              },
            ],
            [
              {
                key: '게시 여부',
                align: 'left',
                value: (
                  <PopupFormGroup
                    list={IN_USE_STATUS_LIST}
                    value={inUseStatus}
                    onChange={setInUseStatus}
                    testId="inUseStatus"
                  />
                ),
              },
            ],
          ]}
        />
        <PopupButtonGroup onSave={handleClickSave} />
      </Box>
    </FileUploadContainer>
  );
};

export default NoticeHomePopup;
