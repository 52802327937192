import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {List, ListItemButton, ListItemText, Collapse, Typography} from '@mui/material';
import {SIDE_MENUS, TSideMenu} from 'constants/Menu';
import {canAccessPage} from 'utils/router';
import useLoginDataStore from 'hooks/useLoginDataStore';

import packageInfo from '../../package.json';

type TProps = {
  active: boolean;
};

const Sidebar = ({active}: TProps) => {
  const location = useLocation();
  const [openStatusMap, setOpenStatusMap] = useState<Record<number, boolean>>({});
  const {role} = useLoginDataStore();

  const menu = useMemo(() => {
    if (!role) {
      return [];
    }

    return SIDE_MENUS.reduce((arr, m) => {
      const subList = m.subList.filter((s) => canAccessPage(s.to, role));

      if (subList.length === 0) {
        return arr;
      }

      return [
        ...arr,
        {
          ...m,
          subList,
        },
      ];
    }, [] as TSideMenu[]);
  }, [role]);

  const handleToggleMenu = useCallback(
    (index) => {
      setOpenStatusMap((prev) => ({
        ...prev,
        [index]: !openStatusMap[index],
      }));
    },
    [openStatusMap]
  );

  useEffect(() => {
    const index = menu.findIndex((v) => v.subList.some((s) => s.to === location.pathname));

    if (index > -1) {
      setOpenStatusMap((prev) => ({
        ...prev,
        [index]: true,
      }));
    }
  }, [menu, location.pathname]);

  return (
    <List
      sx={{
        minWidth: 230,
        bgcolor: 'grey.100',
        color: 'text.primary',
        display: active ? 'block' : 'none',
        zIndex: 10,
        // 100vh - header - sidebar padding
        height: 'calc(100vh - 48px - 16px)',
      }}
      component="nav"
    >
      {menu.map((m, i) => (
        <React.Fragment key={m.title}>
          <ListItemButton onClick={() => handleToggleMenu(i)} dense>
            {m.icon ? m.icon : null}
            <ListItemText primary={m.title} sx={{fontWeight: 700}} />
            {!!openStatusMap[i] ? (
              <ExpandLess fontSize="small" sx={{color: 'text.secondary'}} />
            ) : (
              <ExpandMore fontSize="small" sx={{color: 'text.secondary'}} />
            )}
          </ListItemButton>
          {m.subList && (
            <Collapse in={!!openStatusMap[i]} timeout="auto" unmountOnExit>
              <List component="div" dense>
                {m.subList.map((sub) => (
                  <NavLink
                    style={({isActive}) =>
                      // https://mui.com/customization/default-theme/?expand-path=$.palette
                      isActive
                        ? {
                            textDecoration: 'none',
                            color: '#1565c0',
                          }
                        : {
                            textDecoration: 'none',
                            color: 'rgba(0,0,0,0.87)',
                          }
                    }
                    to={sub.to}
                    key={sub.to}
                  >
                    <ListItemButton sx={{pl: 4}} key={sub.title}>
                      <ListItemText primary={sub.title} />
                    </ListItemButton>
                  </NavLink>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}

      <Typography
        variant="subtitle2"
        sx={{color: 'grey.400', position: 'fixed', bottom: 10, px: 2}}
      >
        pkg ver.{packageInfo.version}
      </Typography>
    </List>
  );
};

export default Sidebar;
