import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';

type TProps = {
  dateRange: TDateRange;
  testId?: string;
  onDateChange?: (date: TDateRange) => void;
};

const PopupTimePicker = ({dateRange, testId, onDateChange}: TProps) => {
  return (
    <AdminDateRangePicker
      testId={testId}
      useTimePicker={true}
      useRangeButton={false}
      autoUpdate={false}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      onDateChange={onDateChange}
      disableMaxDate={true}
      disableMinDate={true}
    />
  );
};

export default PopupTimePicker;
