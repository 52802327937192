import {useCallback, useMemo, useState} from 'react';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import AdminReasonInput from 'components/AdminReasonInput';
import AdminDialog from 'components/AdminDialog';
import AdminMoneyInput from 'components/AdminMoneyInput';
import RequiredStar from 'components/RequiredStar';
import {ERefundType, REFUND_LIST} from 'constants/App';
import {setComma} from 'utils/number';
import {useToastContext} from 'context/ToastContext';
import api from 'utils/api';
import {TTripOrderDetailItem, EOrderType} from 'types/api';
import {delayPageReload} from 'utils/app';

type TProps = {
  open: boolean;
  onClose?: VoidFunction;
  tripOrderItem?: TTripOrderDetailItem;
};

const RefundDialog = ({open, tripOrderItem, onClose}: TProps) => {
  const {show: showToast} = useToastContext();
  const [refundType, setRefundType] = useState<ERefundType>(ERefundType.TOTAL);
  const [refundReason, setRefundReason] = useState('');

  const [unlockAmount, setUnlockAmount] = useState<number>();
  const [ridingAmount, setRidingAmount] = useState<number>();
  const [extraAmount, setExtraAmount] = useState<number>();

  const totalMoney = useMemo(() => tripOrderItem?.paymentAmount || 0, [tripOrderItem]);

  const disableConfirm = useMemo(() => {
    if (!refundReason) {
      return true;
    }

    if (refundType !== ERefundType.TOTAL) {
      return [unlockAmount, ridingAmount, extraAmount].some((v) => typeof v === 'undefined');
    }

    return false;
  }, [refundReason, unlockAmount, ridingAmount, extraAmount, refundType]);

  const handleClose = useCallback(() => {
    setRefundReason('');
    setRefundType(ERefundType.TOTAL);

    onClose?.();
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    if (!tripOrderItem?.tripOrderId) {
      return;
    }

    const tripOrderId = tripOrderItem.tripOrderId;

    try {
      if (refundType === ERefundType.TOTAL) {
        await api.postTotalRefund({
          tripOrderId,
          comment: refundReason,
        });
      } else {
        await api.postRepayment({
          tripOrderId,
          comment: refundReason,
          ridingAmount: ridingAmount || 0,
          extraAmount: extraAmount || 0,
          unlockAmount: unlockAmount || 0,
        });
      }

      showToast('환불처리가 완료되었습니다.', {type: 'success'});
    } catch {
      window.alert('에러가 발생하였습니다.');
    } finally {
      delayPageReload();
    }
  }, [ridingAmount, extraAmount, unlockAmount, showToast, refundType, refundReason, tripOrderItem]);

  if (!tripOrderItem) {
    return null;
  }

  return (
    <AdminDialog
      open={open}
      title={`[${tripOrderItem.orderTypeName}] 환불 처리`}
      desc={
        <Box sx={{width: 450}}>
          <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
            환불 사유
            <RequiredStar />
          </Typography>
          <AdminReasonInput
            onChange={(value) => setRefundReason(value)}
            placeholder="결제 취소 사유를 입력하세요. (100자 이내)"
          />

          <Typography variant="subtitle1" sx={{fontWeight: 'bold', mt: 2}}>
            환불 구분
            <RequiredStar />
          </Typography>
          <FormControl>
            <RadioGroup
              row
              value={refundType}
              onChange={(v) => setRefundType(v.currentTarget.value as ERefundType)}
            >
              {REFUND_LIST.map((s) => (
                <FormControlLabel
                  key={s.key}
                  value={s.key}
                  control={<Radio />}
                  label={s.title}
                  disabled={
                    tripOrderItem.orderType === EOrderType.PENALTY &&
                    s.key === ERefundType.REPAYMENT
                      ? true
                      : false
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>

          {refundType === ERefundType.TOTAL ? (
            <Box>
              <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
                환불 금액
                <RequiredStar />
              </Typography>
              <Typography variant="body1" sx={{height: 32}}>
                {setComma(totalMoney)} 원
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
                잠금해제<em style={{color: 'red'}}> *</em>
              </Typography>
              <AdminMoneyInput onChange={(value) => setUnlockAmount(value)} placeholder={'0'} />
              <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
                주행요금<em style={{color: 'red'}}> *</em>
              </Typography>
              <AdminMoneyInput onChange={(value) => setRidingAmount(value)} placeholder={'0'} />
              <Typography variant="subtitle1" sx={{fontWeight: 'bold', my: 1}}>
                반납추가요금<em style={{color: 'red'}}> *</em>
              </Typography>
              <AdminMoneyInput onChange={(value) => setExtraAmount(value)} placeholder={'0'} />
            </Box>
          )}
        </Box>
      }
      onClose={handleClose}
      confirmText="처리 요청"
      disableConfirm={disableConfirm}
      onConfirm={handleConfirm}
    />
  );
};

export default RefundDialog;
