import {TSelectBoxItem} from 'components/AdminSelectBox';
import {
  EPaymentStatus,
  EPenaltyType,
  ECompanyType,
  ESearchType,
  ETextBannerType,
  EUserStatus,
} from 'types/api';
import Paths from './Paths';

export enum EUserRole {
  // 관리자
  ADMINISTRATOR = 'ADMINISTRATOR',
  // 기획, 운영
  OPERATOR = 'OPERATOR',
  // 제휴사
  PARTNER = 'PARTNER',
  // 고객센터
  SUPPORTER = 'SUPPORTER',
}

export const USER_STATUS_TEXT = {
  [EUserStatus.NORMAL]: '이용가능',
  [EUserStatus.BLOCK]: '이용제한',
};

export const USER_STATUS_LIST = Object.values(EUserStatus).map((c) => ({
  key: c,
  title: USER_STATUS_TEXT[c],
}));

export const COMPANY_TEXT: Record<ECompanyType, string> = {
  [ECompanyType.XINGXING]: '씽씽',
  [ECompanyType.GCOOGER]: '지쿠',
  [ECompanyType.DART]: '다트',
  [ECompanyType.DEER]: '디어',
  [ECompanyType.TMAP]: '티맵 바이크',
};

export const COMPANY_LIST = Object.values(ECompanyType).map((c) => ({
  key: c,
  title: COMPANY_TEXT[c],
}));

export const PENALTY_TEXT = {
  [EPenaltyType.TOWAGE]: '견인료',
  [EPenaltyType.PHOTO]: '주차사진 미촬영',
  [EPenaltyType.PARKING]: '부정주차',
};

export const PENALTY_LIST = Object.values(EPenaltyType).map((p) => ({
  key: p,
  title: PENALTY_TEXT[p],
}));

export enum ERefundType {
  TOTAL = 'TOTAL',
  REPAYMENT = 'REPAYMENT',
}

export const REFUND_TEXT = {
  [ERefundType.TOTAL]: '전체',
  [ERefundType.REPAYMENT]: '재결제',
};

export const REFUND_LIST = Object.values(ERefundType).map((p) => ({
  key: p,
  title: REFUND_TEXT[p],
}));

export const SEARCH_TYPE_TEXT: Record<ESearchType, string> = {
  [ESearchType.PHONE]: '전화번호',
  [ESearchType.TID]: 'TID',
  [ESearchType.USER_KEY]: '유저키',
  [ESearchType.VEHICLE_ID]: '기기시리얼넘버',
  [ESearchType.USER_ID]: '유저아이디',
  [ESearchType.TRIP_ID]: 'TripId',
  [ESearchType.TRIP_ORDER_ID]: 'TripOrderId',
  [ESearchType.COMPANY_TRIP_ID]: '업체 주행 Id',
};

export const PAYMENT_STATUS_TEXT = {
  [EPaymentStatus.PURCHASE_SUCCESS]: '결제완료',
  [EPaymentStatus.PURCHASE_FAIL]: '결제실패',
  [EPaymentStatus.PURCHASE_REQUEST]: '결제요청',

  [EPaymentStatus.CANCEL_SUCCESS]: '취소완료',
  [EPaymentStatus.CANCEL_FAIL]: '취소실패',
  [EPaymentStatus.CANCEL_REQUEST]: '취소요청',
};

export const ALL_ROLE = Object.values(EUserRole);

export const ALLOW_PAGE_MAP = {
  [Paths.MemberStatus]: [EUserRole.OPERATOR, EUserRole.ADMINISTRATOR],
  [Paths.MemberManagement]: [EUserRole.OPERATOR, EUserRole.ADMINISTRATOR],
  [Paths.MemberManageDetail]: [EUserRole.OPERATOR, EUserRole.PARTNER, EUserRole.ADMINISTRATOR],
  [Paths.PaymentDetail]: ALL_ROLE,
  [Paths.UsageHistory]: ALL_ROLE,
  [Paths.CompanyInvoice]: [EUserRole.OPERATOR, EUserRole.PARTNER, EUserRole.ADMINISTRATOR],
  [Paths.CompanyUnsettled]: [EUserRole.OPERATOR, EUserRole.PARTNER, EUserRole.ADMINISTRATOR],
  [Paths.OutstandingAmount]: [EUserRole.ADMINISTRATOR],
  [Paths.NoticeHomePopup]: [EUserRole.ADMINISTRATOR],
  [Paths.NoticeTextBanner]: [EUserRole.ADMINISTRATOR],
  [Paths.NoticeEventPage]: [EUserRole.ADMINISTRATOR],
};

export const ALLOW_UPDATE_ROLE_LIST = [
  EUserRole.OPERATOR,
  EUserRole.SUPPORTER,
  EUserRole.ADMINISTRATOR,
];

export const today = new Date();

export enum EInUseStatus {
  TOTAL = 'TOTAL',
  IN_USE = 'IN_USE',
  NOT_IN_USE = 'NOT_IN_USE',
}

export const IN_USE_STATUS_TEXT = {
  [EInUseStatus.TOTAL]: '전체',
  [EInUseStatus.IN_USE]: '게시',
  [EInUseStatus.NOT_IN_USE]: '중단',
};

const IN_USE_STATUS_VALUE = {
  [EInUseStatus.TOTAL]: undefined,
  [EInUseStatus.IN_USE]: true,
  [EInUseStatus.NOT_IN_USE]: false,
};

export const IN_USE_SELECT_LIST = [
  EInUseStatus.TOTAL,
  EInUseStatus.IN_USE,
  EInUseStatus.NOT_IN_USE,
].map(
  (key) =>
    ({
      key,
      title: IN_USE_STATUS_TEXT[key],
      value: IN_USE_STATUS_VALUE[key],
    } as TSelectBoxItem & {
      value?: boolean;
    })
);

export const IN_USE_STATUS_LIST = [EInUseStatus.IN_USE, EInUseStatus.NOT_IN_USE].map((key) => ({
  key,
  title: IN_USE_STATUS_TEXT[key],
}));

export const TEXT_BANNER_TYPE_TEXT = {
  [ETextBannerType.BENEFIT]: '혜택',
  [ETextBannerType.NOTICE]: '공지',
};

export enum ELandingUrlType {
  HAS_URL = 'HAS_URL',
  NONE = 'NONE',
}

export const LANDING_URL_TITLE = {
  [ELandingUrlType.HAS_URL]: 'url',
  [ELandingUrlType.NONE]: '링크 없음',
};
