import {useCallback, useState} from 'react';
import {generatePath} from 'react-router';
import {subMonths} from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';
import AdminSelectBox, {TSelectBoxItem} from 'components/AdminSelectBox';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {TTableHeader} from 'components/HorizontalKeyTable';
import PagingTable from 'components/PagingTable';
import api from 'utils/api';
import {getSafeDateFormat} from 'utils/date';
import useApi from 'hooks/useApi';
import {ESearchType, EUserStatus, TUserResponse} from 'types/api';
import Paths from 'constants/Paths';
import {ErrorMessage} from 'constants/Error';
import {SEARCH_TYPE_TEXT, today, USER_STATUS_LIST, USER_STATUS_TEXT} from 'constants/App';
import {useToastContext} from 'context/ToastContext';

const USER_SELECT_LIST = [{key: undefined, title: '전체'}, ...USER_STATUS_LIST];

const SEARCH_SELECT_LIST = [ESearchType.PHONE, ESearchType.TID, ESearchType.USER_KEY].map(
  (key) => ({key, title: SEARCH_TYPE_TEXT[key]})
);

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호'},
  {title: 'UserKey'},
  {title: 'UserId'},
  {title: '이름'},
  {title: '회원 상태'},
  {title: 'TID', align: 'left'},
  {title: 'OS'},
  {title: '최근접속일시'},
  {title: '가입일시'},
  {title: '면허 등록 일시'},
];

const PAGING_COUNT = 20;

const MemberManagementPage = () => {
  const {show: showAlert} = useToastContext();
  const [userStatus, setUserStatus] = useState<TSelectBoxItem>(USER_SELECT_LIST[0]);
  const [searchType, setSearchType] = useState<TSelectBoxItem>(SEARCH_SELECT_LIST[0]);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: subMonths(today, 3),
    endDate: today,
  });
  const {fetchApi, loading, response, resetResponse} = useApi<TUserResponse>();

  const getList = useCallback(
    (page: number) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        showAlert(ErrorMessage.VALIDATE_START_END);
        return;
      }

      if (!searchValue) {
        showAlert('검색어를 입력해야 검색이 가능합니다.');
        return;
      }

      fetchApi(() =>
        api.getUserList({
          startDate,
          endDate,
          page,
          blockStatus: userStatus.key as EUserStatus,
          ...(searchValue && {
            searchType: searchType.key as ESearchType,
            searchValue,
          }),
        })
      );
    },
    [fetchApi, dateRange, userStatus, searchValue, searchType, showAlert]
  );

  const handleClickId = useCallback((id) => {
    const url = generatePath(Paths.MemberManageDetail, {
      id,
    });

    window.open(url, '', 'width=1200,height=800');
  }, []);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '가입일',
              value: (
                <AdminDateRangePicker
                  startDate={dateRange?.startDate}
                  endDate={dateRange?.endDate}
                  onDateChange={setDateRange}
                />
              ),
            },
          ],
          [
            {
              key: '회원상태',
              value: (
                <AdminSelectBox
                  currentItem={userStatus}
                  selectList={USER_SELECT_LIST}
                  onClickItem={(m) => setUserStatus(m)}
                />
              ),
            },
          ],
          [
            {
              key: '검색어',
              value: (
                <Box sx={{display: 'flex', alignItems: 'end'}}>
                  <AdminSelectBox
                    currentItem={searchType}
                    selectList={SEARCH_SELECT_LIST}
                    onClickItem={(m) => setSearchType(m)}
                  />
                  <TextField
                    hiddenLabel
                    id="search-input"
                    placeholder="값을 입력해주세요."
                    variant="standard"
                    size="small"
                    margin="none"
                    sx={{mx: 1, width: 480}}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Box>
              ),
            },
          ],
        ]}
      />
      <LoadingButton
        loading={!!loading && !response}
        variant="contained"
        sx={{width: 80, my: 2}}
        onClick={() => {
          resetResponse();
          getList(1);
        }}
      >
        검색
      </LoadingButton>
      {response?.data && (
        <PagingTable
          loading={!!loading}
          headerData={TABLE_HEADER_LIST}
          totalCount={response?.data?.totalCount || 0}
          pagingCount={PAGING_COUNT}
          tableData={
            response?.data?.content.map((c) => [
              c.rowNum,
              c.userKey,
              <Button onClick={() => handleClickId(c.userId)}>{c.userId}</Button>,
              c.userName,
              USER_STATUS_TEXT[c.blockStatus],
              c.tid,
              c.lastAccessOsType,
              getSafeDateFormat(c.lastAccessDateTime),
              getSafeDateFormat(c.activatedDt),
              getSafeDateFormat(c.licenseVerifiedDt),
            ]) || []
          }
          onChangePage={getList}
        />
      )}
    </Box>
  );
};
export default MemberManagementPage;
