import {useCallback, useState} from 'react';
import {TOutstandingRequest} from 'types/api';
import api from 'utils/api';

const useOutstandingAmount = () => {
  const [list, setList] = useState<TOutstandingRequest[]>();

  const requestOutstandingPurchase = useCallback((params: TOutstandingRequest) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        reject('timeout');
      }, 3000);

      try {
        await api.postOutstandingAmountPurchase(params.userKey, params.tripOrderId);
        resolve('success');
      } catch {
        reject('error');
      }
    });
  }, []);

  return {
    list,
    setList,
    requestOutstandingPurchase,
  };
};

export default useOutstandingAmount;
