/* eslint-disable no-console */
import {useState} from 'react';
import AdminDateRangePicker from 'components/AdminDateRangePicker';
import HorizontalKeyTable, {TTableHeader} from 'components/HorizontalKeyTable';
import PagingTable from 'components/PagingTable';
import VerticalKeyTable from 'components/VerticalKeyTable';

const MOCK_TABLE_HEADER: TTableHeader[] = [
  {
    title: '숫자',
  },
  {
    title: '일자',
  },
  {
    title: '트립완료',
    align: 'right',
  },
  {
    title: '트립완료(유니크)',
    align: 'right',
  },
  {
    title: '결제완료',
    align: 'right',
  },

  {
    title: '결제완료(유니크)',
    align: 'right',
  },
  {
    title: '신규가입',
    align: 'right',
  },
  {
    title: '누적회원',
    align: 'right',
  },
];

const getTableMockData = (totalCount: number) =>
  new Array(totalCount)
    .fill(null)
    .map((_, i) => [i + 1, '2021-10-30', 230, 169, 232, 167, 175, '5,555,000']);

const PAGING_COUNT = 5;
const PAGING_TOTAL_COUNT = 32;
const PAGING_TABLE_DATA = getTableMockData(32);

const ProtoComponentPage = () => {
  const [pagingTableData, setPagingTableData] = useState<any[]>(
    PAGING_TABLE_DATA.slice(0, PAGING_COUNT)
  );

  return (
    <div>
      <h4>Date Range Picker</h4>
      <AdminDateRangePicker
        onDateChange={({startDate, endDate}) => {
          console.log('[Date Range Picker]', startDate, endDate);
        }}
      />

      <h4>Vertical Key Table</h4>
      <VerticalKeyTable
        tableData={[
          [{key: '가입일', value: <AdminDateRangePicker />}],
          [{key: '회원상태', value: '전체'}],
          [{key: '검색어', value: '통합검색'}],
        ]}
      />
      <br />
      <VerticalKeyTable
        tableData={[
          [
            {key: '유저키', value: '2021CC***'},
            {key: 'UserId', value: '1111'},
            {key: '이름', value: '정*원'},
          ],
          [
            {key: '핸드폰 번호', value: '010-****-111'},
            {key: '회원 상태', value: '이용가능'},
            {key: '이용제한 사유', value: '-'},
          ],
          [
            {key: 'TID', value: 'tmap****@gmail.com'},
            {key: '생년월일', value: '1992.05.21', colSpan: 3},
          ],
          [
            {key: '가입일시', value: '2020-01-01 23:59:59'},
            {key: '최근 접속 일시', value: '2020-01-01 23:59:59'},
            {key: 'OS 종류', value: 'Android'},
          ],
        ]}
      />

      <h4>Horizontal Key Table</h4>
      <HorizontalKeyTable headerData={MOCK_TABLE_HEADER} tableData={getTableMockData(5)} />

      <h4>Paging Table</h4>
      <PagingTable
        headerData={MOCK_TABLE_HEADER}
        tableData={pagingTableData}
        pagingCount={PAGING_COUNT}
        totalCount={PAGING_TOTAL_COUNT}
        onChangePage={(currentPage) => {
          setPagingTableData(
            PAGING_TABLE_DATA.slice((currentPage - 1) * PAGING_COUNT, currentPage * PAGING_COUNT)
          );
        }}
      />
    </div>
  );
};

export default ProtoComponentPage;
