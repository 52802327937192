import {isProdServer} from 'constants/Env';
import {EErrorCode} from 'constants/Error';

export class CustomError extends Error {
  code?: string;
  status?: number;

  constructor(message: string, code: string, status: number) {
    super(message);
    this.name = 'Custom Error';
    this.code = code;
    this.status = status;
  }
}

const messageCodeMap: Record<string, string> = {
  [EErrorCode.ECONNABORTED]: isProdServer
    ? 'Timeout 에러가 발생하였습니다.'
    : 'VPN 연결을 확인해주세요.',
};

// error code 가 없는 경우
const messageMap: Record<string, string> = {
  'Network Error': '네트워크 에러가 발생하였습니다.',
};

const normalizeError = (e: any) => {
  let code;
  let message;
  let status;

  if (e.response) {
    code = e.response.data.code;
    message = e.response.data.message;
    status = e.response.status;
  } else {
    const errorJSON = e.toJSON();

    code = errorJSON.code;
    message = messageCodeMap[errorJSON.code] || messageMap[errorJSON.message] || errorJSON.message;
    status = errorJSON.status;
  }

  const error = new CustomError(message || '에러가 발생하였습니다.', code, status);

  console.error(error);

  return error;
};

export default normalizeError;
