import {Amplify} from 'aws-amplify';
import {signInWithRedirect, fetchAuthSession, signOut} from 'aws-amplify/auth';
// import { EUserRole } from 'constants/App';
import Paths from 'constants/Paths';
import {EStorageKey} from 'constants/Storage';

import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import loginDataStore from 'store/loginDataStore';
import {localStorageStore} from 'store/stroageStore';
import api from 'utils/api';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '7sosdr4qigdlktffq1hc9t2bl0',
      userPoolId: 'ap-northeast-2_Rr0svoFSL',
      loginWith: {
        oauth: {
          domain: 'tmap-admin.auth.ap-northeast-2.amazoncognito.com',
          scopes: ['email', 'openid', 'phone'],
          redirectSignIn: [`${window.location.origin}/login`],
          redirectSignOut: [`${window.location.origin}/login`],
          responseType: 'code', // token, code
        },
      },
    },
  },
});

const useAuth = () => {
  const navigate = useNavigate();

  const login = useCallback(async () => {
    await signInWithRedirect();
  }, []);

  const logout = useCallback(async () => {
    await signOut();
    try {
      await api.logout();
    } finally {
      loginDataStore.logout();
      if (window.location.pathname !== Paths.Login) {
        navigate(Paths.Login, {replace: true});
      }
    }
  }, [navigate]);

  const checkAuth = useCallback(async () => {
    try {
      const result = await fetchAuthSession();
      localStorageStore.updateState({
        [EStorageKey.LOGIN_TOKEN]: result.tokens?.idToken?.toString(),
      });
      if (!result.tokens) {
        navigate(Paths.Login, {replace: true});
        return;
      }

      const account = await api.getAccountInfo();
      loginDataStore.setState(account.data);

      if (window.location.pathname === Paths.Login) {
        navigate('/', {replace: true});
      }
    } catch {}
  }, [navigate]);

  return {login, logout, checkAuth};
};

export default useAuth;
