import {Dialog} from '@mui/material';
import {useState} from 'react';

type TProps = {
  src: string;
  width: number;
  alt: string;
};

const ImagePreview = ({src, alt, width}: TProps) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgLoadError, setImageLoadError] = useState(false);

  if (imgLoadError || !src) {
    return null;
  }

  return (
    <>
      <img
        width={width}
        src={src}
        onClick={() => setShowImageModal(true)}
        onError={() => setImageLoadError(true)}
        alt={alt}
      />
      <Dialog open={showImageModal} onClose={() => setShowImageModal(false)}>
        <img src={src} alt={alt} />
      </Dialog>
    </>
  );
};

export default ImagePreview;
