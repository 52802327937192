import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useApi from 'hooks/useApi';
import api from 'utils/api';
import {getSafeDateFormat} from 'utils/date';
import {getTitleWithEnv} from 'utils/string';
import {formatMoney, formatPhoneNumber, setComma} from 'utils/number';
import {TPaymentDetailResponse, TTripOrderDetailItem} from 'types/api';
import {COMPANY_TEXT, PAYMENT_STATUS_TEXT} from 'constants/App';
import VerticalKeyTable from 'components/VerticalKeyTable';
import HorizontalKeyTable, {TTableHeader} from 'components/HorizontalKeyTable';
import PenaltyDialog from 'components/paymentDetailPage/PenaltyDialog';
import RefundDialog from 'components/paymentDetailPage/RefundDialog';
import NetworkError from 'components/NetworkError';
import Duration from 'components/Duration';
import ImagePreview from 'components/paymentDetailPage/ImagePreview';

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '번호', width: 30},
  {title: '결제일시'},
  {title: '주문유형'},
  {title: '잠금해제요금', align: 'right'},
  {title: '라이딩요금', align: 'right'},
  {title: '반납추가요금', align: 'right'},
  {title: '할인\n(이벤트)', align: 'right'},
  {title: '포인트', align: 'right'},
  {title: '할인\n(절삭)', align: 'right'},
  {title: '할인\n(운영)', align: 'right'},
  {title: '패널티\n(견인)', align: 'right'},
  {title: '패널티\n(미촬영)', align: 'right'},
  {title: '패널티\n(부정주차)', align: 'right'},
  {title: '최종결제요금', align: 'right'},
  {title: '결제상태'},
  {title: '실패사유'},
  {title: '결제변경사유'},
  {title: '요청자ID'},
  {title: '환불'},
];

const PaymentDetailPage = () => {
  const {fetchApi, response, error, loading} = useApi<TPaymentDetailResponse>();
  const param = useParams();
  const [openPenalty, setOpenPenalty] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [tripOrderItem, setTripOrderItem] = useState<TTripOrderDetailItem>();

  const data = useMemo(() => response?.data, [response]);
  const tripId = useMemo(() => param.id, [param]);
  const historyListLength = useMemo(
    () => response?.data.tripOrderDetailInfo.length || 0,
    [response?.data]
  );

  const handleOpenRefundPopup = useCallback((item) => {
    setTripOrderItem(item);
    setOpenRefund(true);
  }, []);

  const handleCloseRefundPopup = useCallback(() => {
    setTripOrderItem(undefined);
    setOpenRefund(false);
  }, []);

  useEffect(() => {
    if (!!tripId) {
      fetchApi(() => api.getPaymentDetail({tripId}));
    }
  }, [tripId, fetchApi]);

  if (loading === null || (loading && !response)) {
    return <CircularProgress />;
  }

  if (!data || error) {
    return <NetworkError />;
  }

  return (
    <Box sx={{overflowY: 'scroll', height: '100vh'}}>
      <Box sx={{my: 2, mx: 3}}>
        <Typography variant="h6" sx={{mb: 1}}>
          {getTitleWithEnv('결제상세정보')}
        </Typography>
        <Box sx={{mb: 5}}>
          <Typography variant="subtitle1" sx={{mb: 1}}>
            회원정보
          </Typography>
          <VerticalKeyTable
            tableData={[
              [
                {key: '유저키', value: data.userInfo.userKey},
                {key: 'User ID', value: data.userInfo.userId},
                {key: '이름', value: data.userInfo.userName},
              ],
              [
                {key: 'Tid', value: data.userInfo.tid},
                {key: '전화번호', value: formatPhoneNumber(data.userInfo.phone), colSpan: 3},
              ],
            ]}
          />
        </Box>

        <Box sx={{mb: 5}}>
          <Typography variant="subtitle1" sx={{mb: 1}}>
            이용내역
          </Typography>
          <VerticalKeyTable
            tableData={[
              [
                {key: '제휴사명', value: COMPANY_TEXT[data.company]},
                {key: '기기 시리얼넘버', value: data.vehicleId},
                {key: '업체 주행 Id', value: data.companyTripId},
                {key: '결제 구분', value: '일반'},
              ],
              [
                {key: '이용시작시간', value: getSafeDateFormat(data.startDateTime)},
                {key: '이용종료시간', value: getSafeDateFormat(data.endDateTime)},
                {key: '주행시간(s)', value: <Duration duration={data.duration} showHMS={true} />},
                {key: '주행거리(m)', value: setComma(data.distance)},
              ],
              [
                {
                  key: '반납사진',
                  value: (
                    <ImagePreview
                      src={data.pictureUrl || ''}
                      alt={data.userInfo.userName}
                      width={140}
                    />
                  ),
                  colSpan: 7,
                },
              ],
            ]}
          />
        </Box>

        <Box sx={{mb: 5}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography variant="subtitle1" sx={{mb: 1}}>
              결제 이력
            </Typography>

            <Button
              variant="outlined"
              sx={{my: 2}}
              color="error"
              onClick={() => setOpenPenalty(true)}
            >
              패널티 결제요청
            </Button>
          </Box>

          <HorizontalKeyTable
            headerData={TABLE_HEADER_LIST}
            tableData={
              data.tripOrderDetailInfo.map((c, i) => [
                historyListLength - i,
                getSafeDateFormat(c.updDateTime), // 결제일시
                c.orderTypeName,
                formatMoney(c.unlockAmount), // 잠금해제요금
                formatMoney(c.ridingAmount), // 라이딩요금
                formatMoney(c.extraAmount), // 반납추가요금
                formatMoney(c.eventDiscountAmount), // 할인이벤트
                formatMoney(c.pointAmount || 0), // 포인트
                formatMoney(c.roundDownDiscountAmount), // 할인(절삭)
                formatMoney(c.manualDiscountAmount), // 할인(운영)
                formatMoney(c.towPenaltyAmount), // 패널티 (견인)
                formatMoney(c.photoPenaltyAmount), // 패널티 (미촬영)
                formatMoney(c.parkingPenaltyAmount), // 패널치 (부정주차)
                formatMoney(c.paymentAmount), // 최종결제금액
                c.statusName || PAYMENT_STATUS_TEXT[c.status], // 결제상태
                c.failReason || '-', // 실패사유
                c.accountComment || '-', // 결제변경사유
                c.accountUsername || '-', // 요청자 ID,
                c.refundEnable ? (
                  <Button sx={{height: 20}} onClick={() => handleOpenRefundPopup(c)}>
                    환불
                  </Button>
                ) : (
                  '-'
                ),
              ]) || []
            }
          />
        </Box>

        <PenaltyDialog tripId={tripId} open={openPenalty} onClose={() => setOpenPenalty(false)} />
        <RefundDialog
          open={openRefund}
          onClose={handleCloseRefundPopup}
          tripOrderItem={tripOrderItem}
        />
      </Box>
    </Box>
  );
};

export default PaymentDetailPage;
