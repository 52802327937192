import {useCallback, useState} from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';

type TProps = {
  imgUrl: string;
  title: string;
  onClick?: VoidFunction;
};

const AdminImagePopover = ({imgUrl, title, onClick}: TProps) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const [hasError, setError] = useState(imgUrl ? false : true);
  const isPopoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box
      sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {hasError ? (
        <ErrorIcon color="error" />
      ) : (
        <img src={imgUrl} alt="이미지" width={30} height={30} onError={() => setError(true)} />
      )}
      {!hasError && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <img src={imgUrl} alt="이미지" width={300} />
        </Popover>
      )}
      <Button onClick={onClick}>{title}</Button>
    </Box>
  );
};

export default AdminImagePopover;
