import {Box, Button} from '@mui/material';

type TProps = {
  onSave?: VoidFunction;
};

const PopupButtonGroup = ({onSave}: TProps) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        py: 2,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'white',
      }}
    >
      <Button
        variant="outlined"
        sx={{mr: 1}}
        onClick={() => {
          window.alert('입력하신 값이 저장되지 않습니다. 취소 하시겠습니까?');
          window.close();
        }}
      >
        취소
      </Button>
      <Button data-cy="save" variant="contained" onClick={onSave}>
        저장
      </Button>
    </Box>
  );
};

export default PopupButtonGroup;
