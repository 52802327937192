import {useEffect, useState} from 'react';

const useFileUpload = (defaultImg?: string) => {
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState(defaultImg);

  useEffect(() => {
    if (uploadedImage) {
      const result = URL.createObjectURL(uploadedImage);
      setImageUrl((prevUrl) => {
        if (prevUrl) {
          URL.revokeObjectURL(prevUrl);
        }
        return result;
      });
    }
  }, [uploadedImage]);

  return {
    uploadedImage,
    setUploadedImage,
    imageUrl,
  };
};

export default useFileUpload;
