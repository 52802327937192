import {AxiosResponse} from 'axios';

export type TResponse<T> = {
  code: string;
  data: T;
  message: string;
};

export type TPagingRequest<T> = T & {
  page: number;
  size?: number;
};

export type TPagingResponse<T> = {
  content: T[];
  page: number;
  size: number;
  totalCount: number;
  totalPage: number;
};

export type TAxiosResponse<T> = Promise<AxiosResponse<TResponse<T>>>;

export enum ECompanyType {
  XINGXING = 'XINGXING',
  GCOOGER = 'GCOOTER',
  DART = 'DART',
  DEER = 'DEER',
  TMAP = 'TMAP',
}

export enum EPaymentStatus {
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  PURCHASE_FAIL = 'PURCHASE_FAIL',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',

  CANCEL_SUCCESS = 'CANCEL_SUCCESS',
  CANCEL_FAIL = 'CANCEL_FAIL',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
}

export enum ETripStatus {
  START = 'START',
  PAUSE = 'PAUSE',
  END = 'END',
}

export enum EOrderStatus {
  NORMAL = 'NORMAL',
  // TBD 티맵결제
}

type TUserInfo = {
  birth: string;
  phone: string;
  tid: string;
  userId: number;
  userKey: string;
  userName: string;
};

// /api/account/signin
export type TSignInParam = {
  username: string;
  password: string;
};

export type TSignInResponse = {
  accessToken: string;
  tokenType: string;
  expireIn: number;
};

// /api/admin/userstatistics/item
// 가입자 지표
export enum EDateType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type TUserStatisticsParams = TPagingRequest<
  TDateRangeParam & {
    dateType?: EDateType;
  }
>;

export type TUserStatisticsExportParams = TDateRangeParam & {
  dateType?: EDateType;
  excelReason: string;
};

export type TUserStatisticsResponse = TPagingResponse<{
  activeUserCount: number;
  activeUserCumulativeCount: number;
  baseDt: string;
  purchaseCount: number;
  purchaseUserCount: number;
  tripCount: number;
  tripUserCount: number;
}>;

// /api/admin/user/item
// 회원 관리
export enum ESearchType {
  PHONE = 'PHONE',
  TID = 'TID',
  USER_KEY = 'USER_KEY',
  VEHICLE_ID = 'VEHICLE_ID',
  USER_ID = 'USER_ID',
  TRIP_ID = 'TRIP_ID',
  TRIP_ORDER_ID = 'TRIP_ORDER_ID',
  COMPANY_TRIP_ID = 'COMPANY_TRIP_ID',
}

export enum EUserStatus {
  NORMAL = 'NORMAL',
  BLOCK = 'BLOCK',
}

// /api/admin/tripOrder/penalty
// 패널티
export enum EPenaltyType {
  TOWAGE = 'TOW', // 견인
  PHOTO = 'PHOTO', // 주차사진미촬영
  PARKING = 'PARKING', // 부정주차
}

export type TDateRangeParam = {
  startDate: Date;
  endDate: Date;
};

export type TUserParams = TPagingRequest<
  TDateRangeParam & {
    searchType?: ESearchType;
    blockStatus?: EUserStatus;
  }
>;

export type TUserExportParams = TDateRangeParam & {
  blockStatus?: EUserStatus;
  excelReason: string;
};

export type TUserResponse = TPagingResponse<{
  activatedDt: string;
  lastAccessDateTime: string;
  lastAccessOsType: string;
  licenseVerifiedDt: string;
  phone: string;
  rowNum: number;
  blockStatus: EUserStatus;
  tid: string;
  userId: number;
  userKey: string;
  userName: string;
}>;

// `/api/admin/user/${userId}`
export type TUserDetailParams = {
  userId: string;
};

export type TUserDetailResponse = TUserInfo & {
  activatedDt: string;
  lastAccessDateTime: string;
  lastAccessOsType: string;
  licenseVerifiedDt: string;
  blockStatus: EUserStatus;
  blockComment: string;
};

// `/api/admin/user/${userId}/tripOrder`
export type TUserTripDetailParams = TPagingRequest<{
  userId: string;
}>;

export type TUserTripDetailResponse = TPagingResponse<{
  company: ECompanyType;
  distance: number;
  duration: number;
  paymentAmount: number;
  rowNum: number;
  status: EPaymentStatus;
  statusDesc: string;
  tripEndDateTime: string;
  tripId: number;
  tripOrderId: number;
  tripStartDateTime: string;
  updDateTime: string;
  userId: number;
  userKey: string;
  vehicleId: string;
}>;

// `/api/admin/trip/${tripId}/detail`
export type TPaymentDetailParams = {
  tripId: string;
};

export enum EOrderType {
  PENALTY = 'PENALTY',
  NORMAL = 'NORMAL',
}

export type TTripOrderDetailItem = {
  accountComment: Nullable<string>;
  accountUsername: Nullable<string>;
  eventDiscountAmount: number;
  extraAmount: number;
  failReason: Nullable<string>;
  manualDiscountAmount: number;
  parkingPenaltyAmount: number;
  paymentAmount: number;
  photoPenaltyAmount: number;
  pointAmount: Nullable<number>;
  ridingAmount: number;
  roundDownDiscountAmount: number;
  status: EPaymentStatus;
  statusName: string;
  towPenaltyAmount: number;
  tripOrderId: number;
  unlockAmount: number;
  updDateTime: string;
  refundEnable: boolean;
  orderTypeName: string;
  orderType: EOrderType;
};

export type TPaymentDetailResponse = {
  company: ECompanyType;
  companyTripId: string;
  distance: number;
  duration: number;
  endDateTime: string;
  pictureUrl: Nullable<string>;
  startDateTime: string;
  status: ETripStatus;
  tripId: number;
  tripOrderDetailInfo: TTripOrderDetailItem[];
  userInfo: TUserInfo;
  vehicleId: string;
};

// '/api/admin/settlement/item'
export type TSettlementParams = TPagingRequest<
  TDateRangeParam & {
    company: ECompanyType;
  }
>;

export type TSettlementExportParams = TDateRangeParam & {
  company: ECompanyType;
  excelReason: string;
};

export type TSettlementResponse = TPagingResponse<{
  baseDateTime: string;
  commissionRate: number;
  companyCode: ECompanyType;
  companyTripId: string;
  discountAmount: number;
  discountExecution: number;
  discountExecutionAmount: number;
  discountExecutionCommission: number;
  eventDiscountAmount: number;
  extraAmount: number;
  manualDiscountAmount: number;
  orderType: EOrderStatus;
  orderTypeName: string;
  paymentAmount: number;
  paymentExecution: number;
  paymentExecutionAmount: number;
  paymentExecutionCommission: number;
  penaltyAmount: number;
  penaltyParkingAmount: number;
  penaltyPhotoAmount: number;
  penaltyTowAmount: number;
  refundAmount: number;
  ridingAmount: number;
  roundDownDiscountAmount: number;
  rowNum: number;
  status: EPaymentStatus;
  statusName: string;
  totalAmount: number;
  tripAmount: number;
  tripOrderId: number;
  unlockAmount: number;
}>;

// '/api/admin/tripOrder/item'
export type TTripListParams = TPagingRequest<
  TDateRangeParam & {
    company?: ECompanyType;
    status: EPaymentStatus;
    searchType?: ESearchType;
    searchValue?: string;
  }
>;

export type TTripListExportParams = TDateRangeParam & {
  company?: ECompanyType;
  status: EPaymentStatus;
  searchType?: ESearchType;
  searchValue?: string;
  excelReason: string;
};

export type TTripListResponse = TPagingResponse<{
  company: ECompanyType;
  distance: number;
  duration: number;
  paymentAmount: number;
  pointAmount: Nullable<number>;
  rowNum: number;
  status: EPaymentStatus;
  statusDesc: string;
  tripEndDateTime: string;
  tripId: number;
  tripOrderId: number;
  tripStartDateTime: string;
  updDateTime: string;
  userId: number;
  userKey: string;
  vehicleId: string;
  paymentType: string;
}>;

// '/api/admin/settlement/unpaid/item'
export type TUnsettlementParams = TPagingRequest<
  TDateRangeParam & {
    company: ECompanyType;
  }
>;

// /api/admin/settlement/unpaid/item/export
export type TUnsettlementExportParams = TDateRangeParam & {
  company: ECompanyType;
  excelReason: string;
};

export type TUnsettlementResponse = TSettlementResponse;

// /api/admin/user/status
export type TPostUserStatusParams = {
  userId: string;
  blockStatus: EUserStatus;
  comment: string;
};

// /api/admin/tripOrder/penalty
export type TPostPenaltyParams = {
  tripId: string;
  paymentAmount: number;
  penaltyType: EPenaltyType;
};

// /api/admin/tripOrder/cancel
export type TPostTotalRefund = {
  tripOrderId: number;
  comment: string;
};

// /api/admin/tripOrder/repayment
export type TPostRepayment = {
  tripOrderId: number;
  comment: string;
  ridingAmount: number;
  extraAmount: number;
  unlockAmount: number;
};

// /api/admin/popup/item
// /api/admin/textBanner/item
// /api/admin/eventPage/item
export type TPopupParam = TPagingRequest<
  TDateRangeParam & {
    isUse?: boolean;
  }
>;

type TPopupItem = {
  accountName: Nullable<string>;
  endDateTime: string;
  isUse: boolean;
  landingUrl: string;
  title: string;
  regDateTime: string;
  rowNum: number;
  startDateTime: string;
  updDateTime: string;
};

export type THomePopupItem = TPopupItem & {
  popupId: number;
  imgUrl: string;
};

export type THomePopupResponse = TPagingResponse<THomePopupItem>;

// /api/admin/popup/{popupId}/detail
// /api/admin/textBanner/{bannerId}/detail
// /api/admin/eventPage/{bannerId}/detail
export type TPopupDetailParam = {
  id: number;
};

export type THomePopupDetailResponse = THomePopupItem;

export type TPopupUpdateParams = {
  id?: number;
  title: string;
  landingUrl?: string;
  isUse: boolean;
  startDateTime: string;
  endDateTime: string;
  imgUrl?: string;
};

export enum EUploadImageType {
  POPUP = 'POPUP',
  EVENT = 'EVENT',
}

export type TUploadImageParams = {
  type: EUploadImageType;
  file: File;
};

export enum ETextBannerType {
  BENEFIT = 'BENEFIT',
  NOTICE = 'NOTICE',
}

export type TTextBannerUpdateParams = Pick<
  TPopupUpdateParams,
  'id' | 'title' | 'landingUrl' | 'startDateTime' | 'endDateTime' | 'isUse'
> & {
  bannerType: Nullable<ETextBannerType>;
};

export type TTextBannerItem = TPopupItem & {
  bannerId: number;
  bannerType: Nullable<ETextBannerType>;
};

export type TTextBannerResponse = TPagingResponse<TTextBannerItem>;

export type TEventPageItem = TPopupItem & {
  eventPageId: number;
  imgUrl: string;
  buttonName: string;
  buttonColor: string;
};

export type TEventPageListResponse = TPagingResponse<TEventPageItem>;

export type TEventPageUpdateParams = TPopupUpdateParams & {
  buttonName: string;
  buttonColor: string;
};

export type TOutstandingRequest = {
  userKey: string;
  tripOrderId: string;
};
