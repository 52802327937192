const Paths = {
  Login: '/login',

  MemberStatus: '/member/status',
  MemberManagement: '/member/management',
  MemberManageDetail: '/member/management/:id',
  UsageHistory: '/manage/usage-history',
  OutstandingAmount: '/manager/outstanding-amount',
  PaymentDetail: '/member/payment/:id',
  CompanyInvoice: '/invoice/company',
  CompanyUnsettled: '/invoice/unsettled',

  NoticeHomePopup: '/notice/home-popup',
  CreateNoticeHomePopup: '/notice/home-popup/new',
  UpdateNoticeHomePopup: '/notice/home-popup/update/:id',

  NoticeTextBanner: '/notice/text-banner',
  CreateTextBannerPopup: '/notice/text-banner/new',
  UpdateTextBannerPopup: '/notice/text-banner/update/:id',

  NoticeEventPage: '/notice/event',
  CreateEventPagePopup: '/notice/event/new',
  UpdateEventPagePopup: '/notice/event/update/:id',

  // only local
  ProtoComponent: '/proto/component',
};

export default Paths;
