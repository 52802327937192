const numberSplitRegex = (first: number, second: number, third: number) =>
  new RegExp(`^(\\d{${first}})(\\d{${second}})(\\d{${third}})$`);

const formatParsedNumber = (
  str: string,
  splitCountArray: [number, number, number],
  separator = ''
) => {
  const [first, second, third] = splitCountArray;
  const matched = (str || '').match(numberSplitRegex(first, second, third));

  if (!matched) {
    return str;
  }

  const [, m1, m2, m3] = matched;

  return [m1, m2, m3].join(separator);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return formatParsedNumber(phoneNumber, [3, 4, 4], '-') || '-';
};

export const formatBirthday = (birthday: string) => {
  return formatParsedNumber(birthday, [4, 2, 2], '.') || '-';
};

export const setComma = (num: Nullable<number | string>) => {
  return (Number(num) || 0).toLocaleString();
};

export const replaceComma = (str: string) => {
  return `${(str || '').replace(/,/g, '')}`;
};

export const formatMoney = (money?: number | string) => {
  return `${setComma(money || 0)}원`;
};

export const pad = (n) => (n < 10 ? `0${n}` : n);

export const parseSecondsToHMS = (d: number) => {
  if (isNaN(d)) {
    return '';
  }

  const arr = [] as string[];

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  if (h > 0) {
    arr.push(`${pad(h)}시간`);
  }

  if (m > 0) {
    arr.push(`${pad(m)}분`);
  }

  if (s > 0) {
    arr.push(`${pad(s)}초`);
  }

  return arr.join(' ');
};
