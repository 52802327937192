import React, {ReactNode} from 'react';
import classNames from 'classnames';
import AdminCopyToClipboard from './AdminCopyToClipboard';
import RequiredStar from './RequiredStar';
import s from 'styles/components/VerticalKeyTable.module.scss';

export type TVerticalTableData = {
  key: string;
  value: ReactNode;
  align?: 'center' | 'right' | 'left';
  colSpan?: number;
  required?: boolean;
};

type TProps = {
  tableData: TVerticalTableData[][];
};

const VerticalKeyTable = ({tableData}: TProps) => {
  return (
    <table className={s.table}>
      <tbody>
        {tableData.map((d, i) => (
          <tr key={`key-${i}`}>
            {d.map((r, index) => (
              <React.Fragment key={`desc-${index}`}>
                <th
                  className={classNames(s.key, {
                    [s.right]: r.align === 'right',
                    [s.left]: r.align === 'left',
                  })}
                >
                  {r.key}
                  {r.required && <RequiredStar />}
                </th>
                <td className={s.desc} colSpan={r?.colSpan || 1}>
                  <AdminCopyToClipboard value={r.value} />
                </td>
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VerticalKeyTable;
