// https://tde.sktelecom.com/wiki/display/TMAPPM/03.+Status+Code
export enum EAuthErrorCode {
  AUTH_INVALID_ACCESS_TOKEN = 'AUTH_INVALID_ACCESS_TOKEN',
  AUTH_INVALID_USERNAME_AND_PASSWORD = 'AUTH_INVALID_USERNAME_AND_PASSWORD',
  AUTH_ACCESS_DENIED = 'AUTH_ACCESS_DENIED',
}

export enum EErrorCode {
  ECONNABORTED = 'ECONNABORTED',
}

export const ErrorMessage = {
  VALIDATE_START_END: '시작 또는 종료 날짜를 입력하세요.',
};
