import {useCallback, useEffect, useState} from 'react';
import {subMonths} from 'date-fns';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import AdminExcelDownButton from 'components/AdminExcelDownButton';
import AdminDateRangePicker, {TDateRange} from 'components/AdminDateRangePicker';
import AdminSelectBox, {TSelectBoxItem} from 'components/AdminSelectBox';
import VerticalKeyTable from 'components/VerticalKeyTable';
import PagingTable from 'components/PagingTable';
import {TTableHeader} from 'components/HorizontalKeyTable';
import api from 'utils/api';
import {setComma} from 'utils/number';
import {downloadExcel} from 'utils/file';
import useApi from 'hooks/useApi';
import {EDateType, TUserStatisticsResponse} from 'types/api';
import {ErrorMessage} from 'constants/Error';
import {today} from 'constants/App';
import {useToastContext} from 'context/ToastContext';

const DATE_SELECT_LIST = [
  {key: EDateType.DAY, title: '일'},
  {key: EDateType.WEEK, title: '주'},
  {key: EDateType.MONTH, title: '월'},
];

const TABLE_HEADER_LIST: TTableHeader[] = [
  {title: '일자'},
  {title: '트립완료', width: '15%', align: 'right'},
  {title: '트립완료(유니크)', width: '15%', align: 'right'},
  {title: '결제완료', width: '15%', align: 'right'},
  {title: '결제완료(유니크)', width: '15%', align: 'right'},
  {title: '신규가입', width: '15%', align: 'right'},
  {title: '누적회원', width: '15%', align: 'right'},
];

const PAGING_COUNT = 20;

const MemberStatusPage = () => {
  const {show: showAlert} = useToastContext();
  const [dateType, setDateType] = useState<TSelectBoxItem>(DATE_SELECT_LIST[0]);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: subMonths(today, 1),
    endDate: today,
  });
  const {fetchApi, loading, response, resetResponse} = useApi<TUserStatisticsResponse>();

  const getList = useCallback(
    (page: number) => {
      if (!dateRange?.startDate || !dateRange?.endDate) {
        showAlert(ErrorMessage.VALIDATE_START_END);
        return;
      }

      fetchApi(() =>
        api.getUserStatisticsList({
          page,
          startDate: dateRange.startDate as Date,
          endDate: dateRange.endDate as Date,
          dateType: dateType.key as EDateType,
        })
      );
    },
    [fetchApi, dateRange, dateType, showAlert]
  );

  const handleDownload = useCallback(
    (reason: string) => {
      const {startDate, endDate} = dateRange;

      if (!startDate || !endDate) {
        return Promise.reject();
      }

      return api
        .getUserStatisticsListExport({
          startDate,
          endDate,
          dateType: dateType.key as EDateType,
          excelReason: reason,
        })
        .then(downloadExcel);
    },
    [dateRange, dateType]
  );

  useEffect(() => {
    getList(1);
  }, []);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '기준일',
              value: (
                <AdminSelectBox
                  currentItem={dateType}
                  selectList={DATE_SELECT_LIST}
                  onClickItem={setDateType}
                />
              ),
            },
          ],
          [
            {
              key: '기간',
              value: (
                <AdminDateRangePicker
                  views={dateType.key === EDateType.MONTH ? ['year', 'month'] : undefined}
                  startDate={dateRange?.startDate}
                  endDate={dateRange?.endDate}
                  onDateChange={setDateRange}
                />
              ),
            },
          ],
        ]}
      />

      <LoadingButton
        loading={!!loading && !response}
        variant="contained"
        sx={{width: 80, my: 2}}
        onClick={() => {
          resetResponse();
          getList(1);
        }}
        data-cy="search"
      >
        검색
      </LoadingButton>

      {response?.data && (
        <>
          <AdminExcelDownButton onDownload={handleDownload} />
          <PagingTable
            loading={!!loading}
            headerData={TABLE_HEADER_LIST}
            totalCount={response?.data.totalCount || 0}
            pagingCount={PAGING_COUNT}
            tableData={
              response?.data?.content.map((c) => [
                c.baseDt,
                setComma(c.tripCount),
                setComma(c.tripUserCount),
                setComma(c.purchaseCount),
                setComma(c.purchaseUserCount),
                setComma(c.activeUserCount),
                setComma(c.activeUserCumulativeCount),
              ]) || []
            }
            onChangePage={getList}
          />
        </>
      )}
    </Box>
  );
};
export default MemberStatusPage;
