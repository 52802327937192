import {useSyncExternalStore} from 'react';

import loginDataStore from 'store/loginDataStore';

const useLoginDataStore = () => {
  const loginData = useSyncExternalStore(loginDataStore.subscribe, loginDataStore.getState);

  return {
    ...loginData,
  };
};

export default useLoginDataStore;
