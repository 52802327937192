import {ALLOW_PAGE_MAP, ALL_ROLE, EUserRole} from 'constants/App';

export const canAccessPage = (page: string, role?: EUserRole) => {
  if (!role) {
    return false;
  }

  if (page.includes('/proto/')) {
    return ALL_ROLE;
  }

  const allowList = ALLOW_PAGE_MAP[page] || [];

  return allowList.includes(role);
};
