import {useCallback, useState} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {FormControl, TextField, Typography} from '@mui/material';
import AdminDialog from './AdminDialog';
import useLoginDataStore from 'hooks/useLoginDataStore';
import RequiredStar from './RequiredStar';
import format from 'date-fns/format';

type TProps = {
  onDownload: (reason: string) => Promise<any>;
};

const AdminExcelDownButton = ({onDownload}: TProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {name, accountId} = useLoginDataStore();
  const [reason, setReason] = useState<string>('');

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (reason.length < 3) {
      alert('사유는 최소 3자 이상으로 설정해주세요');
      return;
    }
    try {
      setLoading(true);
      await onDownload(reason);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  }, [onDownload, reason]);

  return (
    <>
      <LoadingButton
        loading={loading}
        onClick={handleClick}
        variant="outlined"
        sx={{my: 2, alignSelf: 'flex-end', color: 'success.main'}}
      >
        엑셀 다운로드
      </LoadingButton>
      <AdminDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleConfirm}
        title="엑셀 다운로드 사유"
        desc={
          <div>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold', margin: '12px 0 4px'}}>
              날짜
            </Typography>
            <Typography sx={{color: 'black', fontSize: '14px'}}>
              {format(new Date(), 'yyyy-MM-dd HH:mm')}
            </Typography>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold', margin: '12px 0 4px'}}>
              담당자
            </Typography>
            <Typography
              sx={{color: 'black', fontSize: '14px'}}
            >{`${name}(${accountId})`}</Typography>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold', margin: '12px 0 4px'}}>
              다운로드 사유 <RequiredStar />
            </Typography>
            <FormControl>
              <TextField
                onChange={(e) => setReason(e.target.value)}
                placeholder="사유를 정확하게 작성해 주세요"
                sx={{width: '500px', maxWidth: '100%'}}
                multiline
                autoFocus
              />
            </FormControl>
          </div>
        }
      />
    </>
  );
};

export default AdminExcelDownButton;
