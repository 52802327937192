import {useCallback} from 'react';
import {Box, Button, Typography} from '@mui/material';
import PopupImagePreview from './PopupImagePreview';

type TProps = {
  imgUrl?: string;
  onUploadImage?: (file: File) => void;
  prevImgUrl?: string;
};

const PopupImageUpload = ({onUploadImage, imgUrl, prevImgUrl}: TProps) => {
  const handleUploadImage = useCallback(
    (e) => {
      const img = e.target.files?.[0];

      if (img) {
        onUploadImage?.(img);
      }
    },
    [onUploadImage]
  );

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', mx: 1}}>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
        <label htmlFor="contained-button-image">
          <input
            accept="image/png, image/jpeg"
            id="contained-button-image"
            type="file"
            style={{display: 'none'}}
            data-cy="imageUploader"
            onChange={handleUploadImage}
          />
          <Button variant="contained" component="span" color="success" sx={{mr: 1}}>
            이미지 올리기
          </Button>
        </label>

        <Typography variant="body2">* 620x760 / jpg, png 가능​</Typography>
      </Box>

      <Box sx={{display: 'flex'}}>
        {prevImgUrl && <PopupImagePreview imgUrl={prevImgUrl} isOrigin={true} />}
        {imgUrl && <PopupImagePreview imgUrl={imgUrl} isOrigin={false} />}
      </Box>
    </Box>
  );
};

export default PopupImageUpload;
