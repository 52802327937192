import {useCallback, useEffect, useMemo, useState} from 'react';
import {isSameDay, isWithinInterval, subDays, subMonths, subYears} from 'date-fns';
import ko from 'date-fns/locale/ko';
import {CalendarPickerView} from '@mui/lab';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import {today} from 'constants/App';
import AdminTimePicker from './AdminTimePicker';

import s from 'styles/components/AdminDateRangePicker.module.scss';

export type TDate = Nullable<Date>;
export type TDateRange = {
  startDate?: TDate;
  endDate?: TDate;
};

type TProps = {
  onDateChange?: (date: TDateRange) => void;
  startDate?: TDate;
  endDate?: TDate;
  minDate?: Date;
  disableMaxDate?: boolean;
  disableMinDate?: boolean;
  autoUpdate?: boolean;
  useTimePicker?: boolean;
  useRangeButton?: boolean;
  views?: CalendarPickerView[];
  testId?: string;
};

const DEFAULT_MIN_DATE = new Date('2021-01-01');
const DATE_RANGE_LIST = [
  {
    title: '오늘',
    startDate: today,
  },
  {title: '어제', startDate: subDays(today, 1)},
  {title: '7일', startDate: subDays(today, 7)},
  {title: '1개월', startDate: subMonths(today, 1)},
  {title: '3개월', startDate: subMonths(today, 3)},
  {title: '6개월', startDate: subMonths(today, 6)},
  {title: '1년', startDate: subYears(today, 1)},
];

const AdminDateRangePicker = ({
  onDateChange,
  minDate = DEFAULT_MIN_DATE,
  views,
  disableMaxDate = false,
  disableMinDate = false,
  autoUpdate = true,
  useTimePicker = false,
  useRangeButton = true,
  testId,
  ...dateRange
}: TProps) => {
  const [startDate, setStartDate] = useState<TDate>(dateRange?.startDate || null);
  const [endDate, setEndDate] = useState<TDate>(dateRange?.endDate || null);
  const [isReset, setReset] = useState(false);

  const dateRangeList = useMemo(() => {
    return DATE_RANGE_LIST.filter((d) =>
      minDate ? isWithinInterval(d.startDate, {start: minDate, end: today}) : true
    );
  }, [minDate]);

  const handleChangeStartDate = useCallback(
    (newDate: TDate) => {
      setStartDate(newDate);

      if (autoUpdate && endDate && newDate && newDate > endDate) {
        setEndDate(newDate);
      }
    },
    [endDate, autoUpdate]
  );

  const handleChangeEndDate = useCallback((newDate: TDate) => {
    setEndDate(newDate);
  }, []);

  const handleClickRangeButton = useCallback((item) => {
    setStartDate(item.startDate);
    setEndDate(today);
  }, []);

  const handleClickReset = useCallback(() => {
    setReset(true);
    setStartDate(null);
    setEndDate(null);
  }, []);

  useEffect(() => {
    onDateChange?.({startDate, endDate});
  }, [startDate, endDate]);

  return (
    <Box className={s.date_picker}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <DatePicker
            mask=""
            views={views}
            value={startDate}
            minDate={disableMinDate ? undefined : minDate}
            maxDate={disableMaxDate ? undefined : today}
            onChange={handleChangeStartDate}
            renderInput={(params) => <TextField {...params} data-cy={`${testId}-start`} />}
          />
          {useTimePicker && <AdminTimePicker value={startDate} onChange={setStartDate} />}
          <Typography variant="subtitle1" sx={{mx: 1}}>
            ~
          </Typography>
          <DatePicker
            mask=""
            views={views}
            value={endDate}
            minDate={disableMinDate ? undefined : startDate || undefined}
            maxDate={disableMaxDate ? undefined : today}
            onChange={handleChangeEndDate}
            renderInput={(params) => <TextField {...params} data-cy={`${testId}-end`} />}
          />
          {useTimePicker && <AdminTimePicker value={endDate} onChange={setEndDate} />}
        </Box>
      </LocalizationProvider>

      {useRangeButton && (
        <ButtonGroup sx={{ml: 2, color: 'grey.800', height: 32}}>
          {dateRangeList.map((d) => {
            const isSelected = startDate && isSameDay(startDate, d.startDate);

            return (
              <Button
                size="small"
                key={d.title}
                onClick={() => handleClickRangeButton(d)}
                style={{
                  border: '1px solid',
                  minWidth: 55,
                }}
                variant={isSelected ? 'contained' : undefined}
                color="inherit"
                disableElevation={true}
                data-cy={d.title}
              >
                {d.title}
              </Button>
            );
          })}
        </ButtonGroup>
      )}

      <ReplayIcon
        fontSize="small"
        sx={{
          mx: 1,
          transition: `transform ease-in ${isReset ? 0.35 : 0}s`,
          transform: `rotate(${isReset ? -360 : 0}deg)`,
        }}
        onTransitionEnd={() => setReset(false)}
        onClick={handleClickReset}
      />
    </Box>
  );
};

export default AdminDateRangePicker;
