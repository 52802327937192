import {AxiosResponse} from 'axios';

export const downloadExcel = (response: AxiosResponse) => {
  const filename =
    response.headers['content-disposition']?.match(/filename="(.*?)"/)?.[1] || '데이터.xlsx';
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = url;
  link.style.display = 'none';
  link.setAttribute('download', decodeURIComponent(filename));
  document.body.appendChild(link);
  link.click();
  link.remove();
};
