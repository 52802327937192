import LinearProgress from '@mui/material/LinearProgress';
import classNames from 'classnames';
import AdminCopyToClipboard from './AdminCopyToClipboard';
import s from 'styles/components/HorizontalKeyTable.module.scss';

export type TTableHeader = {
  title: string;
  align?: 'center' | 'right' | 'left';
  width?: number | string;
};

type TProps = {
  headerData: TTableHeader[];
  tableData: any[][];
  loading?: boolean;
};

const HorizontalKeyTable = ({headerData, tableData, loading}: TProps) => {
  return (
    <>
      {loading ? <LinearProgress /> : <div className={s.table_loading} />}
      <table className={s.table}>
        <thead>
          <tr>
            {headerData.map((h) => (
              <th key={h.title} style={{width: h?.width}}>
                {h.title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData.length > 0 ? (
            tableData.map((d, i) => (
              <tr key={i}>
                {d.map((row, index) => (
                  <td
                    key={`row-${index}`}
                    style={{width: headerData[index]?.width}}
                    className={classNames({
                      [s.right]: headerData[index].align === 'right',
                      [s.left]: headerData[index].align === 'left',
                    })}
                  >
                    <AdminCopyToClipboard value={row} />
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headerData.length} className={s.no_data}>
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default HorizontalKeyTable;
