import {format, parseISO} from 'date-fns';

export const parseDate = (date?: string | number | Date) => {
  if (!date) {
    return null;
  }

  return typeof date === 'string'
    ? parseISO(date)
    : typeof date === 'number'
    ? new Date(date)
    : date;
};

export const getSafeDateFormat = (
  date: string | number | Date,
  formatString: string = 'yyyy-MM-dd HH:mm:ss'
): string => {
  if (!date) {
    return '';
  }

  const parsedDate = parseDate(date);

  try {
    if (parsedDate) {
      return format(parsedDate, formatString);
    } else {
      throw new Error('date is undefined');
    }
  } catch (e) {
    console.error(e);
    return typeof date === 'string' ? date : '';
  }
};
