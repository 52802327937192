import {useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import VerticalKeyTable from 'components/VerticalKeyTable';
import {TDateRange} from 'components/AdminDateRangePicker';
import {
  EInUseStatus,
  ELandingUrlType,
  IN_USE_STATUS_LIST,
  TEXT_BANNER_TYPE_TEXT,
} from 'constants/App';
import {ETextBannerType, TTextBannerItem, TTextBannerUpdateParams} from 'types/api';
import {getSafeDateFormat, parseDate} from 'utils/date';
import {useToastContext} from 'context/ToastContext';
import PopupTimePicker from './PopupTimePicker';
import PopupInput from './PopupInput';
import PopupDetailLink from './PopupDetailLink';
import PopupFormGroup from './PopupFormGroup';
import PopupButtonGroup from './PopupButtonGroup';

type TProps = Partial<TTextBannerItem> & {
  onSave?: (params: TTextBannerUpdateParams) => void;
};

const TEXT_BANNER_TYPE_LIST = [ETextBannerType.NOTICE, ETextBannerType.BENEFIT].map((key) => ({
  key,
  title: TEXT_BANNER_TYPE_TEXT[key],
}));

const TextBannerPopup = ({onSave, ...props}: TProps = {}) => {
  const {show: showAlert} = useToastContext();

  const [title, setTitle] = useState(props.title || '');
  const [landingUrl, setLandingUrl] = useState(props.landingUrl || '');

  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: parseDate(props.startDateTime),
    endDate: parseDate(props.endDateTime),
  });

  const [landingUrlType, setLandingUrlType] = useState<ELandingUrlType>(
    props.landingUrl ? ELandingUrlType.HAS_URL : ELandingUrlType.NONE
  );
  const [inUseStatus, setInUseStatus] = useState<EInUseStatus>(
    props.isUse ? EInUseStatus.IN_USE : EInUseStatus.NOT_IN_USE
  );
  const [bannerType, setBannerType] = useState<ETextBannerType>(
    props.bannerType || ETextBannerType.NOTICE
  );

  const handleClickSave = useCallback(async () => {
    if (!title || !dateRange.startDate || !dateRange.endDate) {
      showAlert('필수 값을 모두 입력해주세요.');
      return;
    }

    onSave?.({
      title,
      bannerType,
      landingUrl: landingUrlType === ELandingUrlType.HAS_URL ? landingUrl : undefined,
      isUse: inUseStatus === EInUseStatus.IN_USE ? true : false,
      startDateTime: getSafeDateFormat(dateRange.startDate),
      endDateTime: getSafeDateFormat(dateRange.endDate),
    });
  }, [title, dateRange, showAlert, inUseStatus, landingUrl, landingUrlType, bannerType, onSave]);

  return (
    <Box>
      <VerticalKeyTable
        tableData={[
          [
            {
              key: '구분',
              align: 'left',
              required: true,
              value: (
                <PopupFormGroup
                  list={TEXT_BANNER_TYPE_LIST}
                  value={bannerType}
                  onChange={setBannerType}
                />
              ),
            },
          ],
          [
            {
              key: '제목',
              align: 'left',
              required: true,
              value: <PopupInput title={title} onChange={setTitle} />,
            },
          ],
          [
            {
              key: '게시기간',
              align: 'left',
              required: true,
              value: <PopupTimePicker dateRange={dateRange} onDateChange={setDateRange} />,
            },
          ],
          [
            {
              key: '상세링크',
              align: 'left',
              value: (
                <PopupDetailLink
                  landingUrl={landingUrl}
                  onChange={setLandingUrl}
                  landingUrlType={landingUrlType}
                  onChangeType={setLandingUrlType}
                />
              ),
            },
          ],
          [
            {
              key: '게시 여부',
              align: 'left',
              value: (
                <PopupFormGroup
                  list={IN_USE_STATUS_LIST}
                  value={inUseStatus}
                  onChange={setInUseStatus}
                />
              ),
            },
          ],
        ]}
      />
      <PopupButtonGroup onSave={handleClickSave} />
    </Box>
  );
};

export default TextBannerPopup;
